import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';

@Injectable({
  providedIn: 'root',
})
export class FreelyAuthService implements OnInit {
  authTokenSubject = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient, private authService: SocialAuthService) {
    //this.loadFromStorage();
  }

  private authToken: string | null = null;
  private userIdentity: SocialUser | null = null;
  private loggedIn: boolean = false;

  get getAuthToken(): string | null {
    return this.authToken;
  }

  get getUserIdentity(): SocialUser | null {
    return this.userIdentity;
  }

  get getIsLoggedIn(): boolean {
    return this.loggedIn;
  }

  private loadFromStorage() {
    let token = localStorage.getItem('freely-auth-user');
    if (token != null) {
      this.authToken = token;
      this.loggedIn = true;
    } else {
      this.authToken = null;
      this.loggedIn = false;
    }
    this.authTokenSubject.next(this.authToken);
  }

  ngOnInit(): void {
    this.authService.authState.subscribe((user) => {
      this.login(user).subscribe((success) => {
        console.log('logged in success:', success);
        if (success == true) {
          this.userIdentity = user;
          this.loggedIn = true;
        } else {
          this.userIdentity = null;
          this.loggedIn = false;
          this.authToken = null;
        }
        this.authTokenSubject.next(this.authToken);
      });
    });
  }

  logout() {
    this.authToken = null;
    this.loggedIn = false;
    this.userIdentity = null;
    localStorage.removeItem('freely-auth-user');
    this.authTokenSubject.next(this.authToken);
  }

  login(user: SocialUser): Observable<boolean> {
    if (user == null) {
      return of(false);
    }
    const apiUrl = `${environment.FREELY_API_URL}/auth/login`;

    console.log('calling url', apiUrl);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const payload = {
      ticket: user.idToken,
    };

    return this.http.post(apiUrl, payload, { headers, observe: 'response' }).pipe(
      map((response) => {
        if (response.status === 201) {
          const authToken = response.headers.get('Authorization');
          if (!authToken) {
            this.authTokenSubject.next(null);
            return false;
          }
          this.authToken = authToken;
          localStorage.setItem('freely-auth-user', authToken);
          this.authTokenSubject.next(this.authToken);
          return true;
        }
        this.authTokenSubject.next(null);
        return false;
      })
    );
  }

  // }

  //   this.http.post(apiUrl, {
  //       ticket: this.user.idToken
  //     },{
  //        headers, observe: 'response'
  //     }).pipe( map ( response => {
  //       const responseBody = response.body;
  //       const responseHeaders = response.headers

  //       console.log(responseHeaders)
  //       console.log(responseBody)
  //     }

  //     ), catchError(this.handleError))

  //     console.log(mytest)

  //     mytest.subscribe((response) => {
  //       console.log("rsponse", response)
  //     })
  //    } else {
  //     console.log("NO GO")
  //    }
  // }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error occurred';
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
//   private isLoggedIn = new BehaviorSubject(false);
//   private loggedInIdentity = new BehaviorSubject(new SocialUser());

//   login(user: SocialUser) {
//     if(user: SocialUser)

//     const apiUrl = `${environment.FREELY_API_URL}\auth\login`;

//     const headers = new HttpHeaders()
//       .set('Content-Type', 'application/json');

//     this.http.post(this.apiUrl, {
//       ticket: this.authService.
//     })

//     return this.http.get<any>(this.apiUrl, { headers }).pipe(
//       map(response => {
//         return response.report.map((item: { name_company: any; display_name: any; name_first: any; name_last: any; email: any; accounting_id: any; notes: any; id: any; }) => ({
//           companyName: item.name_company,
//           displayName: item.display_name,
//           contactFirstName: item.name_first,
//           contactLastName: item.name_last,
//           contactEmail: item.email,
//           companyAccountNumber: item.accounting_id,
//           notes: item.notes,
//           transoftId: item.id
//         }));
//       }),
//       catchError(this.handleError)
//     );

//   }
//   private handleError(error: HttpErrorResponse) {
//     let errorMessage = 'Unknown error occurred';
//     if (error.error instanceof ErrorEvent) {
//       // Client-side error
//       errorMessage = `Error: ${error.error.message}`;
//     } else {
//       // Server-side error
//       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
//     }
//     console.error(errorMessage);
//     return throwError(() => new Error(errorMessage));
//   }

//   }

// }
