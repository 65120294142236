import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { UserAuthService } from '../../../user/services/user-auth.service';
import { OverlayService } from '../../../../shared/services/overlay.service';
import { AvatarComponent } from '../../../../shared/components/avatar/avatar.component';

@Component({
  selector: 'app-header',
  standalone: true,
  templateUrl: './header.component.html',
  imports: [
    RouterLink,
    OverlayModule,
    MatButton,
    MatIcon,
    AvatarComponent
  ],
})
export class HeaderComponent {
  isOpen = false;
  constructor(
    protected route: Router,
    protected userAuthService: UserAuthService,
    protected overlayService: OverlayService,
  ) {

  }

  logout() {
    //this.overlayService.showOverlay();
    this.userAuthService.logout().subscribe(() => {
      // this.route.navigate(['/login']);
      // this.overlayService.hideOverlay();
    });
    this.route.navigate(['/login']);
  }
}
