import { NgModule } from '@angular/core';
import { TransactionsRoutingModule } from './transactions-routing.module';
import { ConfigModule } from './config/config.module';


@NgModule({
  imports: [
    TransactionsRoutingModule,
    ConfigModule
  ],
  providers: [
   
  ]
})
export class TransactionsModule { }
