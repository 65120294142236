import { NgModule } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { LayoutEmptyComponent } from '../common/components/layout-empty/layout-empty.component';
import { LayoutDefaultComponent } from '../common/components/layout-default/layout-default.component';
import { UserAuthGuard } from './guards/user-auth-guard';

@NgModule({
  declarations: [],
  imports: [
  ],
  providers: [
    provideRouter([
      {
        path: 'dashboard',
        component: LayoutDefaultComponent,
        canActivate: [UserAuthGuard],
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/dashboard/dashboard.component').then((x) => x.DashboardComponent),
          }
        ]
      },
      {
        path: 'login',
        component: LayoutEmptyComponent,
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/login/login.component').then((x) => x.LoginComponent),
          }
        ]
      }
    ])
  ]
})
export class UserRoutingModule { }
