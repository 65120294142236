import { NgModule } from '@angular/core';
import { AdminRoutingModule } from './admin-routing.module';
import { provideConfig } from 'core/config';


@NgModule({
  imports: [
    AdminRoutingModule
  ],
  providers: [
    provideConfig({
      api: {
        endpoints: {
          adminCompanyProfile: 'admin/company-profile',
          adminSettings: 'admin/settings',
        },
      }
    })
  ]
})
export class AdminModule { }
