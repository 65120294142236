import { Component } from '@angular/core';
import { SocialUser } from "@abacritt/angularx-social-login";
import { OverlayService } from './shared/services/overlay.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'Freely Payments Acceptance Portal';

  user: SocialUser = new SocialUser;
  $loading = this.overlayService.$loading;

  constructor(
    protected overlayService: OverlayService,
  ){}
}

