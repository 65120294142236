import { NgModule } from '@angular/core';
import { environment } from 'environments/environment';
import { provideDefaultConfig } from 'core/config';

import { ApiMockFactoryService } from './services/api-mock-factory.service';
import { ApiFactoryService } from './services/api-factory.service';
import { ApiFactoryFacade } from './services/api-factory.facade';

import { IApiConfig } from './services/api.interface';

declare module 'core/config'
{
  interface Config {
    api?: IApiConfig;
    apiAuth?: IApiConfig;
  }
}

@NgModule({
  providers: [
    provideDefaultConfig({
      api: {
        apiUrl: `${ environment.FREELY_API_URL }`,
      },
      apiAuth: {
        apiUrl: `${ environment.FREELY_API_URL }`,
      }
    }),
    ApiMockFactoryService,
    ApiFactoryService,
    {
      provide: ApiFactoryFacade,
      useFactory: (api: ApiFactoryService, mock: ApiMockFactoryService) => {
        if (environment.apiMode === 'mock') {
          return mock;
        } else {
          return api;
        }
      },
      deps: [ApiFactoryService, ApiMockFactoryService]
    }
  ]
})
export class ApiModule { }
