import { Subject, takeUntil } from 'rxjs';
import { MessageComponent } from './message.component';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { AlertMessageService, MessageConfig } from './alert-message.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'alert-message',
  standalone: true,
  imports: [NgIf, NgFor, CommonModule, MessageComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="message-container">
      <message
        *ngFor="let message of messages; let i = index"
        [message]="message"
        [id]="i"
        (processed)="processMessage($event)"
      ></message>
    </div>
  `,
  styles: [
    `
      .message-container {
        position: fixed;
        padding: 10px;
        min-width: 20%;
        color: darkslategray;
        z-index: 2000;
        top: 1em;
        right: 1em;
      }
    `,
  ],
})
export class AlertMessageComponent implements OnInit, OnDestroy {
  messageId!: any;
  messageConfig!: MessageConfig;
  displayMessage: boolean = false;
  messages: Array<MessageConfig> = [];
  messageSubject: Subject<void> = new Subject<void>();

  constructor(private alertMessageService: AlertMessageService, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.alertMessageService.alertEvent.pipe(takeUntil(this.messageSubject)).subscribe((data: MessageConfig) => {
      this.addMessage([data]);
    });
  }

  addMessage(messages: MessageConfig[]): void {
    this.messages = messages;
    this.cd.markForCheck();
  }

  processMessage(id: number) {
    this.messages?.splice(id, 1);
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.messageSubject.next();
    this.messageSubject.complete();
  }
}
