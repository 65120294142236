import { NgModule } from '@angular/core';
import { CustomerRoutingModule } from './customer-routing.module';
import { provideConfig } from 'core/config';

@NgModule({
  imports: [
    CustomerRoutingModule
  ],
  providers: [
    provideConfig({
      api: {
        endpoints: {
          customersListCustomer: 'customers',
          customersGetCustomer: 'customer/${customerId}',
          customersUpdateCustomer: 'customer/${customerId}',
          customersDeleteCustomer: 'customer/${customerId}',
          customersCreateCustomer: 'customer',
          customersPaymentMethods: 'customer/${customerId}/tokens',
          customersCreatePaymentMethod: 'customer/${customerId}/token',
          customersDeletePaymentMethod: 'customer/${customerId}/token/${paymentMethodId}',
          customersGetPaymentMethod: 'customer/${customerId}/token/${paymentMethodId}',
        },
      }
    })
  ]
})
export class CustomerModule {
}
