import { ChangeDetectorRef, Inject, Injector, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'cardExpiration',
  standalone: true
})
export class CardExpirationPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) protected locale: string
  ) {}
  transform(
    value: string|undefined,
    options = {
      fromFormat: /^(?<month>.*)\/(?<year>.*)$/,
      toFormat: 'MM/yy'}
  ): any  {
    if (!value) {
      return '';
    }

    const { fromFormat, toFormat } = options;

    const fromPattern = typeof fromFormat === 'string' ? new RegExp(fromFormat) : fromFormat;
    const { month, year } = fromPattern.exec(value)!.groups ?? {month: null, year: null};
    const monthInt = parseInt(month ?? '') - 1; // js month start from zero
    const yearInt = parseInt(year ?? '');
    const expDate = new Date(yearInt, monthInt, 5, 0, 0, 0, 0);
    const datePipe = new DatePipe(this.locale);
    return datePipe.transform(expDate, toFormat);
  }
}
