import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { LayoutDefaultComponent } from '../common/components/layout-default/layout-default.component';
import { UserAuthGuard } from '../user/guards/user-auth-guard';



@NgModule({
  providers: [
    provideRouter([
      {
        path: 'reports',
        component: LayoutDefaultComponent,
        canActivate: [UserAuthGuard],
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/reports/reports.component'),
          }
        ]
      },
    ], withComponentInputBinding()),
  ]
})
export class ReportsRoutingModule { }
