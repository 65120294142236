import { Component, OnInit, ViewChild, ElementRef, viewChild } from '@angular/core';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput, MatHint } from '@angular/material/input';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { PaymentMethodModel } from '../../../customer/customer.model';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';


type CardDetailsFormType = FormGroup<CardDetailsFormControlsType>;

type CardDetailsFormControlsType = {
  account: FormControl<string | null>,
  cardHolderName: FormControl<string | null>,
  expirationDate: FormControl<string | null>,
  cvv: FormControl<string | null>,
  streetAddress: FormControl<string | null>,
  zipCode: FormControl<string | null>,
  isDefault: FormControl<boolean | null>,
  notes: FormControl<string | null>,
  bypassCVV: FormControl<boolean | null>,
  bypassAVS: FormControl<boolean | null>,
};

type CardDetailsFormValueType = {
  account: string | null,
  cardHolderName: string | null,
  expirationDate: string | null,
  cvv: string | null,
  streetAddress: string | null,
  zipCode: string | null,
  isDefault: boolean | null,
};

@Component({
  selector: 'app-card-details-form',
  standalone: true,
  imports: [
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatSlideToggle,
    NgxMaskDirective

  ],
  templateUrl: './card-details-form.component.html',
  host: {
    class: 'app-card-details-form'
  },
  providers: [
    provideNgxMask()
  ]
})
export class CardDetailsFormComponent implements OnInit {
  @ViewChild('expirationDateInput') expirationDateInput!: ElementRef;
  @ViewChild('cvvInput') cvvInput!: ElementRef;
  @ViewChild('accountInput') accountInput!: ElementRef;
  @ViewChild('cardHolderNameInput') cardHolderNameInput!: ElementRef;
  @ViewChild('notesInput') notesInput!: ElementRef;
  @ViewChild('addressInput') addressInput!: ElementRef;
  @ViewChild('zipInput') zipInput!: ElementRef;
  
  data: {
    payment: PaymentMethodModel | null
  };
  accPrefix = "";
  cardMask = '9';
  cvvMask = '000';
  isViewMode = false;

  isCardInvalid = false;
  isCvvInvalid = false;
  isAvsInvalid = false;
  invalidMessage = 'This is the invalid message';


  form: CardDetailsFormType = new FormGroup<CardDetailsFormControlsType>({
    account: new FormControl(null, [
      Validators.required,
      Validators.pattern(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|6(?:011|5[0-9]{2})[0-9]{12}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)
    ]),
    cardHolderName: new FormControl(null, Validators.required),
    expirationDate: new FormControl(null, [
      Validators.required,
      Validators.pattern(/^(0[1-9]|1[0-2])([0-9]{2})$/),
      this.expirationDateValidator,
      this.expirationDateMaxValidator
    ]),
   // cvv: new FormControl(null, [Validators.required]),
    cvv: new FormControl(null, [
      Validators.required,
      this.bypassCVVValidator.bind(this)
    ]),
    streetAddress: new FormControl(null, [Validators.required, Validators.maxLength(25), this.bypassAVSValidator.bind(this)]),
    zipCode: new FormControl(null,[ 
      Validators.required,
        Validators.pattern(/^\d{5}$/)
        ]),
    isDefault: new FormControl(false),
    notes: new FormControl(null),
    bypassCVV: new FormControl(false), 
    bypassAVS: new FormControl(false)
  });

  expirationDateMaxValidator(control: FormControl): { [key: string]: boolean } | null {
    if (!control.value) {
      return null;
    }

    const today = new Date();
    const maxDate = new Date(today.getFullYear() + 5, today.getMonth() + 1);
    const [month, year] = control.value.match(/.{1,2}/g)!.map(Number);
    const expirationDate = new Date(2000 + year, month - 1);

    if (expirationDate > maxDate) {
      return { 'expirationDateTooFar': true };
    }

    return null;
  }

  getErrors() {
    return JSON.stringify(this.form.get('streetAddress')?.errors);
  }

  bypassCVVValidator(control: FormControl): { [key: string]: boolean } | null {
    if (!control.value) {
      return null;
    }
    if (this.isCvvInvalid && this.form.get('bypassCVV')?.value==false) {
      return { 'bypassCVVInvalid': true };
    } 
    return null;
  }

  bypassAVSValidator(control: FormControl): { [key: string]: boolean } | null {
    if (!control.value) {
      return null;
    }
    if (this.isAvsInvalid && this.form.get('bypassAVS')?.value==false) {
      return { 'bypassAVSInvalid': true };
    } 
    return null;
  }

  expirationDateValidator(control: FormControl): { [key: string]: boolean } | null {
    if (!control.value) {
      return null;
    }

    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear() % 100;

    const [month, year] = control.value.match(/.{1,2}/g)!.map(Number);

    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      return { 'expirationDateInvalid': true };
    }

    return null;
  }

  constructor() {
  }

  async onAccountFilled() {
    this.expirationDateInput.nativeElement.focus();
  }

  ngOnInit(): void {
    if (this.data?.payment) {
      this.form.patchValue({
        ...{ cvv: '-', notes: '-' },
        ...this.data.payment
      });
      this.isViewMode = true;
    }
    this.form.get('expirationDate')?.valueChanges.subscribe((value) => {
      if (value && value.length == 4) {
        if (this.form.controls.expirationDate.valid) {
          this.cvvInput.nativeElement.focus();
        } else {
          this.cvvInput.nativeElement.focus();
          this.expirationDateInput.nativeElement.focus();
        }
      }
    });
    this.form.get('account')?.valueChanges.subscribe((value) => {
      try {

        if (value && value.length > 0) {
          switch (value.substring(0, 1)) {
            case "6":
            case "5":
            case "4":
              this.cardMask = '9000 0000 0000 0000';
              this.cvvMask = '000';
              if (value.length == 16) {
                
                this.onAccountFilled();
              }

              break;
            case "3":
              this.cardMask = '9000 000000 00000';
              this.cvvMask = '0000';
              if (value.length == 15) {
                this.onAccountFilled();
              }

              break;
            default:
              this.cardMask = '9';
              this.cvvMask = '000';
              break;
          }
        }
      } catch (e) {
        this.cardMask = '9000 0000 0000 0000'
        this.cvvMask = '000'
      }
    });
    this.form.get('cvv')?.valueChanges.subscribe((value) => {
      if(this.form.controls.cvv.valid) {
        this.cardHolderNameInput.nativeElement.focus();
      }
    });
    this.form.get('zipCode')?.valueChanges.subscribe((value) => {
      if(this.form.controls.zipCode.valid) {
        this.notesInput.nativeElement.focus();
      }
    });
    
  }
}
