<section>
  <div *ngTemplateOutlet="selected ? selected.content : null; context: {form: form}"></div>



  <ul class="app-payment-collect-stepper__progress-bar">
    @for (step of steps; track step; let i = $index) {
      <li
        class="app-payment-collect-stepper__progress-bar-item"
        [class.active]="i <= selectedIndex"
      ></li>
    }
  </ul>
  <footer class="app-payment-collect-stepper__navigation-bar">
    <div class="app-payment-collect-stepper__navigation-bar-left">
    @if (selectedIndex > 0) {
      <button mat-button color="primary" cdkStepperPrevious #prevButton type="button">
        <mat-icon fontSet="fa" fontIcon="fa-arrow-left" />
        prev
      </button>
    }
    </div>
    <div class="app-payment-collect-stepper__navigation-bar-right">
    @if (selectedIndex < steps.length - 1) {
      <button mat-raised-button color="primary" cdkStepperNext #nextButton type="button" [disabled]="!isNextButtonAllowed()">next</button>
      <span class="press-enter-subtitle">press enter <mat-icon fontSet="fa fa-solid" fontIcon="fa-arrow-turn-down" /></span>
    } @else {
      <button mat-raised-button color="primary" type="submit">submit</button>
    }
    </div>
  </footer>
</section>
