import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
} from '@angular/core';
import { MessageConfig } from './alert-message.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'message',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('showHide', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(100%)' }),
        animate('.5s ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('.5s ease-out', style({ opacity: 0, transform: 'translateY(100%)' })),
      ]),
    ]),
  ],

  template: ` <div
    @showHide
    class="message"
    [ngClass]="{
      error: message.type === 'ERROR',
      success: message.type === 'SUCCESS',
      warn: message.type === 'WARN'
    }"
  >
    <h3>{{ message.heading }}</h3>
    <p [innerHTML]="message.message"></p>
  </div>`,
  styles: [
    `
      .message {
        padding: 10px;
        margin: 0 0 1rem 0;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
        border-radius: 3px;
      }

      .error {
        background: #ffcdd2;
        border: solid #e60017;
        border-width: 0 0 0 6px;
        color: #73000c;
      }
      .success {
        background: #c8e6c9;
        border: solid #439446;
        border-width: 0 0 0 6px;
        color: #224a23;
      }

      .warn {
        background: #ffecb3;
        border: solid #d9a300;
        border-width: 0 0 0 6px;
        color: #6d5100;
      }
    `,
  ],
})
export class MessageComponent implements AfterViewInit, OnDestroy {
  timerId: any;
  @Input() id!: number;
  @Input() message!: MessageConfig;
  @Output() processed: EventEmitter<any> = new EventEmitter<any>();

  constructor(private zone: NgZone) {}

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.timerId = setTimeout(() => {
        this.processed.emit(this.id);
      }, this.message.duration || 5000);
    });
  }

  public clearTimeout() {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  }

  ngOnDestroy(): void {
    this.clearTimeout();
  }
}
