import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { Subscription } from 'rxjs';
import { invert } from 'lodash-es';

const paymentCollectSteps: {[key:string]: number} = {
  'customer': 0,
  'payment': 1,
  'invoice': 2,
  'summary': 3,
};

@Component({
  selector: 'app-payment-collect-stepper',
  standalone: true,
  templateUrl: './stepper.component.html',
  host: {
    'class': 'app-payment-collect-stepper',
  },
  providers: [ { provide: CdkStepper, useExisting: PaymentCollectStepperComponent } ],
  imports: [
    NgTemplateOutlet,
    CdkStepperModule,
    MatButton,
    MatIcon,
    NgClass,
    ReactiveFormsModule
  ],
})
export class PaymentCollectStepperComponent extends CdkStepper implements OnInit, OnDestroy {
  @ViewChild('nextButton', {read: MatButton, static: false}) nextButton : MatButton;
  @ViewChild('prevButton', {read: MatButton, static: false}) prefButton : MatButton;
  @Input() set selectedStep(value: string) {
    if (paymentCollectSteps[value] !== undefined) {
      this.selectedIndex = paymentCollectSteps[value];
    }
  }
  @Input() allowNext$ = false;
  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    event.preventDefault();
    this.nextButton._elementRef.nativeElement.click();
  }

  @Input() form = new UntypedFormGroup({});

  subscription = new Subscription();

  ngOnInit(): void {
  }

  isNextButtonAllowed(){



    const currentFormName = invert(paymentCollectSteps)[this.selectedIndex];

    return !!this.form.get(currentFormName)?.valid;



  }

  selectStepByIndex(index: number): void {
    this.selectedIndex = index;
  }

  override next(): void {
    if (this.isNextButtonAllowed()) {
      super.next();
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }



}
