import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashcase',
  standalone: true
})
export class DashCasePipe implements PipeTransform {
  transform(value: string): any {
    return value.toLowerCase().replace(/ +/g, '-');
  }
}
