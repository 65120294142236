export const environmentBase = {
  config: {
    number: {
      currency: {
        format: {
          separator: '.',
          precision: 2,
          scale: 2,
          thousands: {
            separator: ','
          }
        }
      }

    },
    payment: {
      precision: 2,
      calculator: {
        capital: {
          percent: 3
        }
      }
    }
  },
  googleAuth: {
    clientId: '620640685404-rkdh1li43sfng9qium9fspn08uojigak.apps.googleusercontent.com',
    options: {
      oneTapEnabled: false, // default is true
      scopes: [ 'profile', 'email' ]
    }
  }
}
