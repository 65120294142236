import { NgModule } from '@angular/core';
import { PipesModule } from './pipes/pipes.module';


@NgModule({
  imports: [
    PipesModule
  ]
})
export class SharedModule { }
