import { NgModule } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { LayoutEmptyComponent } from '../common/components/layout-empty/layout-empty.component';
import { LayoutDefaultComponent } from '../common/components/layout-default/layout-default.component';
import { UserAuthGuard } from '../user/guards/user-auth-guard';

@NgModule({
  providers: [
    provideRouter([
      {
        path: 'customers',
        component: LayoutDefaultComponent,
        canActivate: [UserAuthGuard],
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/customers/customers.component').then((x) => x.CustomersComponent),
          }
        ]
      },
    ], withComponentInputBinding()),
  ]
})
export class CustomerRoutingModule { }
