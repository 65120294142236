import { Injectable } from '@angular/core';
import { EnvironmentService } from '../../../shared/services/environment.service';

export interface PaymentConfigInterface {
  precision: number;
  calculator: {
    capital: {
      percent: number;
    }
  };
}

@Injectable({
  providedIn: 'root'
})
export class PaymentCalculatorService {
  config: PaymentConfigInterface = this.environmentService.get('config.payment');
  constructor(
    protected environmentService: EnvironmentService
  ) { }

  calculateCapital(amount: number) {
    const percent = this.config.calculator.capital.percent;
    return this.round(amount * percent / 100);
  }

  round(value: number) {
    return Number(value).toFixed(this.config.precision);
  }
}
