import { Injectable } from '@angular/core';
import {get,set} from 'lodash-es';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  constructor() { }
  get(key: string) {
    return get(environment, key);
  }
  set(key: string, value: any) {
    return set(environment, key, value);
  }
}
