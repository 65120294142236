import { Component } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { NgClass } from '@angular/common';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-layout-default',
  standalone: true,
  templateUrl: './layout-default.component.html',
  host: {
    class: 'layout layout-default'
  },
  imports:[
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
    NgClass
  ]
})
export class LayoutDefaultComponent {

}
