import { Injector } from '@angular/core';
import { ModalComponentInterface, ModalConfig, ModalService } from '../../../shared/components/modal/modal.service';
import { GridColumnActionType } from '../../../shared/components/grid/grid-config';
import { PaymentMethodModel } from '../../customer/customer.model';
import { ActionsService } from '../../customer/services/actions.service';
import { CardDetailsFormComponent } from '../components/card-details-form/card-details-form.component';
import { ModalComponent } from 'shared/components/modal/modal/modal.component';

enum PaymentMethodActionNames {
 // 'view' = 'view',
  'delete' = 'delete',
}

const openModal = (injector: Injector, data: ModalConfig): void => {
  const modalService = injector.get(ModalService);
  modalService.openModal(data);
}

const paymentMethodActionHandlers: { [key in PaymentMethodActionNames]: GridColumnActionType<PaymentMethodModel> } = {
  // [PaymentMethodActionNames.view]: {
  //   label: 'View',
  //   handler: (paymentMethod: PaymentMethodModel, component: Object, injector: Injector) => {
  //     openModal(injector, {
  //       title: 'Customer Information',
  //       body: CardDetailsFormComponent,
  //       data: {
  //         payment: paymentMethod
  //       },
  //       confirm: null,
  //       dismiss: {
  //         label: 'Close',
  //         action: (modal: ModalComponentInterface) => {
  //           modal.close();
  //         }
  //       }
  //     });
  //   }
  // },
  [PaymentMethodActionNames.delete]: {
    label: 'Delete',
    handler: (paymentMethod: PaymentMethodModel, component: Object, injector: Injector) => {
      const actionsService = injector.get(ActionsService);
      openModal(injector!, {
        title: 'Delete Payment Method',
        body: `Are you sure you want to delete payment #${ paymentMethod.account }?`,
        dismiss: {
          label: 'Cancel',
          action(modal: ModalComponentInterface) {
            modal.close();
          }
        },
        confirm: {
          label: 'Delete',
          action: (modal: ModalComponentInterface) => {
            actionsService.handlePaymentDeleteAction(
              paymentMethod,
              () => modal.close()
            );
          }
        }
      });
    },
  }
}

export const paymentMethodActions = [
 // paymentMethodActionHandlers[PaymentMethodActionNames.view],
  paymentMethodActionHandlers[PaymentMethodActionNames.delete],
];

