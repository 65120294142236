import { NgModule } from '@angular/core';
import { ApiModule } from './api/api.module';
import { ConfigModule } from './config/config.module';

@NgModule({
  imports: [
    ApiModule,
    ConfigModule
  ],
  providers: [

  ]
})
export class CoreModule { }
