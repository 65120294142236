<div class="footer-left">
  Copyright © 2024 freely payments. All rights reserved.
</div>
<div class="footer-middle">
  <a routerLink="/privacy">Privacy Policy</a>
  <a routerLink="/contact">Contact Support</a>
</div>
<div class="footer-right">
  <a href="#"><mat-icon fontSet="fa" fontIcon="fa-phone" /></a>
  <a href="#"><mat-icon fontSet="fa" fontIcon="fa-envelope" /></a>
  <a href="#"><mat-icon fontSet="fab" fontIcon="fa-linkedin-in" /></a>
  <a href="#"><mat-icon fontSet="fab" fontIcon="fa-x-twitter" /></a>
  <a href="#"><mat-icon fontSet="fab" fontIcon="fa-instagram" /></a>
  <a href="#"><mat-icon fontSet="fab" fontIcon="fa-facebook-f" /></a>
</div>
