<header class="header">
  <app-header ngClass="container"></app-header>
</header>
<main class="main container">
  <div class="main-sidebar">
    <router-outlet name='sidebar'></router-outlet>
  </div>
  <div class="main-content">
    <router-outlet></router-outlet>
  </div>
</main>
<footer class="footer">
  <app-footer ngClass="container"></app-footer>
</footer>
