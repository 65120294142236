<div class="page__header">
  <div class="page__header-flex">
    <div class="page__header-left">
      <div class="logo">
        <div class="logo__image"><img src="assets/logos/freely-logo-black.png" alt="History"/></div>
        <div class="logo__slogan">accept</div>
      </div>
    </div>
    <div class="page__header-right">
      <button mat-stroked-button routerLink="/history">exit</button>
    </div>
  </div>
</div>
<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
<app-payment-collect-stepper #stepper [form]="form" [allowNext$]="$allowNext" >
  <cdk-step>
    <h2 class="step-title">From which customer would you like to collect payment?</h2>
    <p class="step-subtitle">
      <button
        type="button"
        mat-button color="primary"
        (click)="openAddCustomerModal()"
      >add customer</button>
    </p>

    <app-payment-collect-customers
      formControlName="customer"
    />
  </cdk-step>
  <cdk-step>
    <h2 class="step-title">Which card should be charged?</h2>
    <p class="step-subtitle">
      <button
        type="button"
        mat-button color="primary"
        (click)="openAddPaymentMethodModal()"
      >add card</button>
    </p>
    <app-payment-collect-payment-methods
      [customerId]="form.get('customer')?.getRawValue()?.id"
      formControlName="payment"
    />
  </cdk-step>
  <cdk-step>
    <h2 class="step-title invoice-details">What are the invoice details?</h2>
    <app-invoice-details formControlName="invoice" #invoiceDetails [form]="invoiceDetailsForm"  [allowNext$]="$allowNext" />
  </cdk-step>
  <cdk-step>
    <h2 class="step-title summary">Final step. Does everything look correct?</h2>
    <app-summary [form]="form" (editClick)="stepper.selectedStep=$event"/>
  </cdk-step>
</app-payment-collect-stepper>
</form>
