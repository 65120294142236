import { Injectable } from '@angular/core';
import { IApiConfig } from './api.interface';
import { ApiService } from './api.service';
import { ApiFactoryFacade } from './api-factory.facade';

@Injectable()
export class ApiFactoryService extends ApiFactoryFacade {
  create(config: IApiConfig) {
    return new ApiService(config);
  }
}
