import { NgModule } from '@angular/core';
import { provideConfig } from '../../../core/config/config-providers';

@NgModule({
  providers: [
    provideConfig({
      api: {
        endpoints: {
            transaction: 'transaction/${transactionId}',
            transactionRefund: 'transaction/${transactionId}/refund',
            transactionPurchase: 'transaction/purchase',
            transactionSale: 'transaction/sale',
            transactions: 'transactions',
            transactionsList: 'transactions/list',
            transactionsListOpen: 'transactions/open',
            transactionsListClosed: 'transactions/closed',
            transactionsListFailed: 'transactions/declined',
            transactionsListBatches: 'transactions/batches',
            batches: 'batches',
          },
        }
      })
  ],
})

export class ConfigModule { }

