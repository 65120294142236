import { Component } from '@angular/core';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { NgClass } from '@angular/common';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-layout-two-column-left',
  standalone: true,
  imports: [
    FooterComponent,
    HeaderComponent,
    NgClass,
    RouterOutlet
  ],
  templateUrl: './layout-two-column-left.component.html',
  host: {
    class: 'layout layout-two-column-left'
  },
})
export class LayoutTwoColumnLeftComponent {

}
