import { Injector, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'lastmodified',
  standalone: true,
})
export class LastModifiedPipe implements PipeTransform {
  constructor(
    protected injector: Injector,
  ) {
  }
  transform(value: string, format: string = 'shortDate'): any {
    if (!value) {
      return 'N/A';
    }
    const datePipe = this.injector.get(DatePipe);
    return datePipe.transform(value, format);
  }
}
