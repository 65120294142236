import { NgModule } from '@angular/core';
import { provideRouter } from '@angular/router';
import { UserAuthGuard } from '../user/guards/user-auth-guard';
import {
  LayoutTwoColumnLeftComponent
} from '../common/components/layout-two-column-left/layout-two-column-left.component';


@NgModule({
  providers: [
    provideRouter([
      {
        path: 'administration',
        component: LayoutTwoColumnLeftComponent,
        canActivate: [ UserAuthGuard ],
        children: [
          // redirect to company-profile by default
          {
            path: '',
            redirectTo: 'company-profile',
            pathMatch: 'full',
          },
          // side navigation
          {
            path: '',
            loadComponent: () => import('./components/navigation/navigation.component'),
            outlet: 'sidebar'
          },
          {
            path: 'company-profile',
            loadComponent: () => import('./pages/company-profile/company-profile.component'),
          },
          {
            path: 'user-access',
            loadComponent: () => import('./pages/user-access/user-access.component'),
          },
          {
            path: 'account-management',
            loadComponent: () => import('./pages/account-management/account-management.component'),
          },
          {
            path: 'settings',
            loadComponent: () => import('./pages/settings/settings.component'),
          }
        ]
      }
    ])
  ]
})
export class AdminRoutingModule {
}
