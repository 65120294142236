import { isDevMode, NgModule } from '@angular/core';
import { NgIf } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideRouter, RouterLink, RouterLinkActive, RouterOutlet, withComponentInputBinding } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { BrowserModule } from '@angular/platform-browser';
import { provideStore } from '@ngrx/store';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { authInterceptor } from './services/authorization.interceptors';
import { AppComponent } from './app.component';
import { AlertMessageComponent } from './shared/components/alert-message.component';
import { OverlayComponent } from './shared/components/overlay/overlay.component';
import { routes } from './app.routes';
import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { NGX_MAT_ERROR_CONFIG_EN, NgxMatErrorsModule } from 'ngx-mat-errors';
import { FeaturesModule } from './features/features.module';
import { CoreModule } from './core/core.module';



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    RouterOutlet, RouterLink, RouterLinkActive,
    NgIf,
    NgxMatErrorsModule,

    AlertMessageComponent,
    OverlayComponent,
    BrowserModule,

    CoreModule,
    SharedModule,
    FeaturesModule
  ],
  providers: [
    provideHttpClient(withInterceptors([ authInterceptor ])),
    provideRouter(routes, withComponentInputBinding()),
    provideStore({
      router: routerReducer,
    }),


    provideRouterStore(),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true // If set to true, the connection is established within the Angular zone
    }),
    provideAnimationsAsync(),
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.googleAuth.clientId, environment.googleAuth.options
            )
          },
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    NGX_MAT_ERROR_CONFIG_EN
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
