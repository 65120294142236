import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { FreelyAuthService } from './freely-auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const freelyService = inject(FreelyAuthService);
  const token = freelyService.getAuthToken;
  const snackBar = inject(MatSnackBar);
  if (token) {

    console.log('authInterceptor', token);
    const cloned = req.clone({
      setHeaders: {
        authorization: token,
      },
    });
    return next(cloned).pipe(
      catchError((err) => {
        if (err.status === 401) {
          freelyService.logout();
          snackBar.open('Session Expired, please log in', '', { duration: 10000 });
        }
        return throwError(() => err);
      })
    );
  }
  return next(req);
};
