import { Component, DestroyRef, OnInit } from '@angular/core';
import { TransactionsService } from '../../../transactions/services/transactions.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CdkStep } from '@angular/cdk/stepper';
import { InvoiceDetailsComponent } from '../../components/payment-collect/invoice-details/invoice-details.component';
import { MatButton, MatMiniFabButton } from '@angular/material/button';
import { PaymentCollectCustomersComponent } from '../../components/payment-collect/customers/customers.component';
import {
  PaymentCollectPaymentMethodsComponent
} from '../../components/payment-collect/payment-methods/payment-methods.component';
import { PaymentCollectStepperComponent } from '../../components/payment-collect/stepper/stepper.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SummaryComponent } from '../../components/payment-collect/summary/summary.component';
import { CardExpirationPipe } from '../../../../shared/pipes/card-expiration.pipe';
import { CcMaskPipe } from '../../../../shared/pipes/cc-mask.pipe';
import { MatIcon } from '@angular/material/icon';
import { CurrencyPipe, NgClass } from '@angular/common';
import { CustomerModel, PaymentMethodModel } from '../../../customer/customer.model';
import { TransactionDetail, Transaction } from '../../../transactions/transaction.model';
import { InvoiceDetailsInterface } from '../../payment.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-collect-confirm',
  standalone: true,
  imports: [
    CdkStep,
    InvoiceDetailsComponent,
    MatButton,
    PaymentCollectCustomersComponent,
    PaymentCollectPaymentMethodsComponent,
    PaymentCollectStepperComponent,
    ReactiveFormsModule,
    RouterLink,
    SummaryComponent,
    CardExpirationPipe,
    CcMaskPipe,
    MatIcon,
    MatMiniFabButton,
    NgClass,
    CurrencyPipe
  ],
  templateUrl: './collect-success.component.html',
  host: {
    class: 'page page-payment-collect-success'
  }
})
export class CollectSuccessComponent implements OnInit {
  customer?: CustomerModel | null;
  payment?: PaymentMethodModel | null;
  invoice?: InvoiceDetailsInterface | null;
  transaction?: TransactionDetail | null;

  constructor(
    protected transactionApiService: TransactionsService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected destroyRef: DestroyRef,
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap((params: { transactionId?: string }) => {
          return this.transactionApiService.getTransaction(params.transactionId!)
        })
      )
      .subscribe((transaction) => {
        this.transaction = transaction;
        console.log('transaction', transaction);
      });
  }

  getCardExpirationOptions() {
    return {
      fromFormat: /^(?<month>.{2})(?<year>.{2})$/,
      toFormat: 'MM/yy'
    };
  }
}
