import { CommonModule, UpperCasePipe } from '@angular/common';
import {
  Component,
  computed,
  DestroyRef,
  effect,
  forwardRef,
  inject,
  Input, Signal,
  signal,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { PaymentMethodModel } from '../../../../customer/customer.model';
import { paymentMethodsConfig } from '../../../config/payment-methods';
import { GridComponent } from '../../../../../shared/components/grid/grid.component';
import { CustomersService } from '../../../../customer/services/customers.service';
import { DashCasePipe } from '../../../../../shared/pipes/dash-case.pipe';
import { PaymentCollectStore } from '../../../store/payment-collect.store';

interface PaymentMethodModelWithForm extends PaymentMethodModel {
  isDefaultFormControl: FormControl<boolean | null>;
}

type IsDefaultForm = FormArray<IsDefaultFormItem>;
type IsDefaultFormItem = FormGroup<{
  paymentMethodId: FormControl<string | null>;
  isDefault: FormControl<boolean | null>;
}>;

@Component({
  selector: 'app-payment-collect-payment-methods',
  templateUrl: './payment-methods.component.html',
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => PaymentCollectPaymentMethodsComponent)
  } ],
  standalone: true,
  imports: [
    CommonModule,
    GridComponent,
    MatCheckbox,
    ReactiveFormsModule,
    DashCasePipe,
    UpperCasePipe
  ],
  host: {
    class: 'app-payment-collect-payment-methods'
  }
})
export class PaymentCollectPaymentMethodsComponent implements ControlValueAccessor {
  @ViewChild('setDefault') setDefaultTemplate: TemplateRef<any>;
  @ViewChild('cardType') cardType: TemplateRef<any>;

  config = paymentMethodsConfig;

  @Input() get customerId(): string {
    return this._customerId();
  };

  set customerId(value: string) {
    this._customerId.set(value);
  }

  _customerId = signal('');

  readonly store = inject(PaymentCollectStore);
  readonly destroyRef = inject(DestroyRef);
  $data: Signal<any> = computed(() => {
    const paymentMethods = this.store.data.paymentMethods().map((paymentMethod) => Object.assign({}, paymentMethod));
    this.isDefaultForm.clear();
    return paymentMethods.map((paymentMethod: PaymentMethodModel) => {

      const formControl = new FormControl<boolean>(false);
      const formGroup = new FormGroup({
        paymentMethodId: new FormControl<string | null>(paymentMethod!.id ?? null),
        isDefault: formControl
      });
      this.isDefaultForm.push(formGroup);
      (paymentMethod as PaymentMethodModelWithForm).isDefaultFormControl = formControl;

      formGroup.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((values) => {
          // uncheck all other checkboxes
          this.isDefaultForm.controls.forEach((control) => {
            const paymentMethodId = control.get('paymentMethodId')?.value;
            if (paymentMethodId !== values.paymentMethodId) {
              control.get('isDefault')?.setValue(false, { emitEvent: false });
            }
            this.setDefaultPaymentMethod(values.paymentMethodId as string);
          });
        });

      return paymentMethod as PaymentMethodModelWithForm;
    });
  })

  isDefaultForm: IsDefaultForm = new FormArray<IsDefaultFormItem>([]);

  protected onChange: (paymentMethodId: PaymentMethodModel) => void = () => {
  };
  protected onTouched: () => void = () => {
  };
  protected selected: PaymentMethodModel = {};

  constructor(
    protected customerApiService: CustomersService
  ) {
    effect(() => {

    });
  }

  setDefaultPaymentMethod(paymentMethodId: string) {
    console.log('default payment method is set');
  }

  writeValue(paymentMethodId: PaymentMethodModel): void {
    this.selected = paymentMethodId;
  }

  registerOnChange(fn: (paymentMethodId: PaymentMethodModel) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  selectPaymentMethod(paymentMethod: PaymentMethodModel) {
    this.selected = paymentMethod;
    this.onChange(this.selected);
  }

  isSelectedFunc(): (paymentMethod: PaymentMethodModel) => boolean {
    return (paymentMethod: PaymentMethodModel) => (this.selected?.id === paymentMethod.id);
  }

  templateRefs(): { [key: string]: TemplateRef<any> } {
    return {
      'setDefault': this.setDefaultTemplate,
      'cardType': this.cardType
    };
  }

  ngOnDestroy(): void {
  }
}
