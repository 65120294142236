

export const apiRoutes =
{
    endpoints: {
        reportMetadata: 'reports/${reportType}/metadata',
        reportsList: 'reports',
        reportsCreate: 'report',
        reportUpdate: 'report/${reportId}/update',
        reportDelete: 'report/${reportId}',
        reportGet: 'report/${reportId}',
    }
};