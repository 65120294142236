import { CurrencyPipe, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatIcon } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PaymentCollectFormType } from '../../../pages/collect/collect.component';
import { CardExpirationPipe } from '../../../../../shared/pipes/card-expiration.pipe';
import { CcMaskPipe } from '../../../../../shared/pipes/cc-mask.pipe';

@Component({
  selector: 'app-summary',
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatSlideToggle,
    MatIcon,
    NgClass,
    CardExpirationPipe,
    CcMaskPipe,
    CurrencyPipe
  ],
  templateUrl: './summary.component.html',
  host: {
    class: 'app-summary'
  }
})
export class SummaryComponent {
  @Output() editClick = new EventEmitter<string>();
  @Input() form: PaymentCollectFormType | null = null;
  onEditClick(value: string) {
    this.editClick.emit(value);
  }
  getCardExpirationOptions() {
    return {
      fromFormat: /^(?<month>.{2})(?<year>.{2})$/,
      toFormat: 'MM/yy'
    };
  }

  sanitizeCurrency(value: number) {
    if (isNaN(value)) {
      return 0
    }
    return value;
  }
}
