import { NgModule } from '@angular/core';
import { UserRoutingModule } from './user-routing.module';
import { ConfigModule } from './config/config.module';
import { AuthStore } from 'features/user/store/auth.store';
import { UserApiService, UserAuthApiService } from 'features/user/api/api-tokens';
import { ApiFactoryFacade } from 'core/api/services/api-factory.facade';
import { Config } from 'core/config';
import { UserAuthService } from 'features/user/services/user-auth.service';

@NgModule({
  imports: [
    ConfigModule,
    UserRoutingModule
  ],
  providers: [
    AuthStore,
    {
      provide: UserApiService,
      useFactory: (apiFactory: ApiFactoryFacade, config: Config) => {
        return apiFactory.create(config.api!);
      },
      deps: [ApiFactoryFacade, Config]
    },
    {
      provide: UserAuthApiService,
      useFactory: (apiFactory: ApiFactoryFacade, config: Config) => {
        return apiFactory.create(config.apiAuth!);
      },
      deps: [ApiFactoryFacade, Config]
    },
    UserAuthService,
  ]
})
export class UserModule { }
