import { Inject, Injectable, Injector } from '@angular/core';
import { Config, ConfigChunk, DefaultConfigChunk } from './config-tokens';
import { deepMerge } from '../../shared/utils/deep-merge';

@Injectable()
export class ConfigService {

  constructor(
    protected config: Config,
    protected injector: Injector,
    @Inject(DefaultConfigChunk) protected defaultConfigChunks: Config,
    @Inject(ConfigChunk) protected configChunk: Config
  ) {
  }

  initialize() {
    deepMerge(this.config as Record<string, unknown>, this.defaultConfigChunks, this.configChunk);
  }
}
