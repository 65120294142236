<ng-template #setDefault let-column="column" let-element="element">
  <mat-checkbox [formControl]="element.isDefaultFormControl" [value]="element.id"/>
</ng-template>
<ng-template #cardType let-column="column" let-element="element">
  <span class="card-type-{{element.accountType|dashcase}}">
    {{ element.accountType | uppercase }}
  </span>
</ng-template>
<app-grid
  [data]="$data"
  [config]="config"
  (select)="selectPaymentMethod($event)"
  [isSelectedFunc]="isSelectedFunc()"
  [templateRefs]="templateRefs()"
/>
