import { NgClass } from '@angular/common';
import { Component, forwardRef, OnDestroy, OnInit, ViewChild, ElementRef, input, Input } from '@angular/core';
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Subscription } from 'rxjs';

import { PaymentCalculatorService } from '../../../services/payment-calculator.service';
import { InvoiceDetailsInterface } from '../../../payment.model';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { AbstractControl } from '@angular/forms';
import { NgxCurrencyDirective } from 'ngx-currency';
import { valueProperty } from 'ag-charts-community/dist/types/src/module-support';



@Component({
  selector: 'app-invoice-details',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    NgClass,
    NgxMaskDirective,
    NgxCurrencyDirective
  ],
  templateUrl: './invoice-details.component.html',
  host: {
    class: 'app-invoice-details'
  },
  providers: [
    provideNgxMask(),

    {

      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InvoiceDetailsComponent)
    }],
})
export class InvoiceDetailsComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('taxAmountInput') taxAmountInput!: ElementRef;
  @ViewChild('capitalAmountInput') capitalAmountInput!: ElementRef;
  @ViewChild('shippingAmountInput') shippingAmountInput!: ElementRef;
  @Input() allowNext$ : boolean = false;


  @Input() form = new UntypedFormGroup({});
  
  
  // form: UntypedFormGroup =  new UntypedFormGroup({
  //   invoiceNumber: new FormControl<string>('', [
  //     Validators.required,
  //     Validators.minLength(3),
  //     Validators.maxLength(25)
  //   ]),
  //   invoiceTotalAmount: new FormControl<number | null>(null, [
  //     Validators.required,
  //     Validators.min(0.01),
  //     Validators.max(999999.99)
  //   ]),
  //   enableTaxAmount: new FormControl<boolean>(false),
  //   taxAmount: new FormControl<number | null>(null, [
  //     Validators.min(0.01),
  //     (control: AbstractControl) => {
  //       const enableTaxAmount = this.form?.get('enableTaxAmount')?.value;
  //       if (enableTaxAmount && (control.value === null || control.value <= 0)) {
  //         return { required: true };
  //       }
  //       return null;
  //     },
  //   (control: AbstractControl) => {
  //     const enableTaxAmount = this.form?.get('enableTaxAmount')?.value;
  //     const invoiceTotalAmount = this.form?.get('invoiceTotalAmount')?.value;
  //     if (enableTaxAmount && control.value > (invoiceTotalAmount * 0.15)) {
  //       return { maxTaxAmount: true };
  //     }
  //     return null;
  //   }
  //   //  this.taxValidator.bind(this)

  //   ]),
  //   enableShippingAmount: new FormControl<boolean>(false),
  //   shippingAmount: new FormControl<number | null>(null, [
  //     Validators.min(0.01),
  //     (control: AbstractControl) => {
  //       const enableShippingAmount = this.form?.get('enableShippingAmount')?.value;
  //       if (enableShippingAmount && (control.value === null || control.value <= 0)) {
  //         return { required: true };
  //       }
  //       return null;
  //     }
  //   ]),
  //   capitalAmount: new FormControl<number | null>(null, [
  //     Validators.min(0.01),
  //     (control: AbstractControl) => {
  //       const enableCapitalAmount = this.form?.get('enableCapitalAmount')?.value;
  //       if (enableCapitalAmount && (control.value === null || control.value <= 0)) {
  //         return { required: true };
  //       }
  //       return null;
  //     },
  //     (control: AbstractControl) => {
  //       const enableCapitalAmount = this.form?.get('enableCapitalAmount')?.value;
  //       const invoiceTotalAmount = this.form?.get('invoiceTotalAmount')?.value;
  //       if (enableCapitalAmount && control.value > (invoiceTotalAmount * 0.15)) {
  //         return { maxCapitalAmount: true };
  //       }
  //       return null;
  //     }
  //   ]
  //   ),
  //   enableCapitalAmount: new FormControl<boolean>(false),
  //   totalAmount: new FormControl<number>(0),
  //   isFullyValidated: new FormControl<boolean>(false) 
  // });






  protected onChange: (value: string) => void = () => { };
  protected onTouched: () => void = () => { };

  subscription = new Subscription();

  constructor(
    protected paymentCalculatorService: PaymentCalculatorService
  ) {





  }

  calculateTotalAmount(): void {
    const invoiceTotalAmount = parseFloat(this.form.get('invoiceTotalAmount')?.value ?? 0);
    let capitalAmount = 0;
    if (this.form.get('enableCapitalAmount')?.value) {
      capitalAmount = parseFloat(this.form.get('capitalAmount')?.value ?? 0);
    }
    const totalAmount = invoiceTotalAmount + capitalAmount;
    this.form.get('totalAmount')?.setValue(totalAmount);
  }



  ngOnInit(): void {

    this.form.addControl('invoiceNumber', new FormControl<string>('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(25)
    ]));
    this.form.addControl('invoiceTotalAmount', new FormControl<number | null>(null, [
      Validators.required,
      Validators.min(0.01),
      Validators.max(999999.99)
    ]));
    this.form.addControl('enableTaxAmount', new FormControl<boolean>(false));
    this.form.addControl('taxAmount', new FormControl<number | null>(null, [
      Validators.min(0.01),
      (control: AbstractControl) => {
        const enableTaxAmount = this.form?.get('enableTaxAmount')?.value;
        if (enableTaxAmount && (control.value === null || control.value <= 0)) {
          return { required: true };
        }
        return null;
      },
    (control: AbstractControl) => {
      const enableTaxAmount = this.form?.get('enableTaxAmount')?.value;
      const invoiceTotalAmount = this.form?.get('invoiceTotalAmount')?.value;
      if (enableTaxAmount && control.value > (invoiceTotalAmount * 0.15)) {
        return { maxTaxAmount: true };
      }
      return null;
    }]));
    this.form.addControl('enableShippingAmount', new FormControl<boolean>(false));
    this.form.addControl('shippingAmount', new FormControl<number | null>(null, [
      Validators.min(0.01),
      (control: AbstractControl) => {
        const enableShippingAmount = this.form?.get('enableShippingAmount')?.value;
        if (enableShippingAmount && (control.value === null || control.value <= 0)) {
          return { required: true };
        }
        return null;
      }
    ]));
    this.form.addControl('capitalAmount', new FormControl<number | null>(null, [
      Validators.min(0.01),
      (control: AbstractControl) => {
        const enableCapitalAmount = this.form?.get('enableCapitalAmount')?.value;
        if (enableCapitalAmount && (control.value === null || control.value <= 0)) {
          return { required: true };
        }
        return null;
      },
      (control: AbstractControl) => {
        const enableCapitalAmount = this.form?.get('enableCapitalAmount')?.value;
        const invoiceTotalAmount = this.form?.get('invoiceTotalAmount')?.value;
        if (enableCapitalAmount && control.value > (invoiceTotalAmount * 0.15)) {
          return { maxCapitalAmount: true };
        }
        return null;
      }
    ]
    ));
    this.form.addControl('enableCapitalAmount', new FormControl<boolean>(false));
    this.form.addControl('totalAmount', new FormControl<number>(0));
    this.form.addControl('isFullyValidated', new FormControl<boolean>(false));


    this.disableEnableControl('taxAmount', false);
    this.disableEnableControl('shippingAmount', false);
    this.disableEnableControl('capitalAmount', false);

    this.subscription.add(
      this.form.valueChanges.subscribe(result => {
        this.onChange(result ?? {});  
      })
    );
  
    this.subscription.add(

      this.form.get('enableCapitalAmount')?.valueChanges.subscribe(result => {
        this.disableEnableControl('capitalAmount', result);
        if (result) {
          const invoiceTotalAmount = parseFloat(this.form.get('invoiceTotalAmount')?.value ?? 0);
          const defaultCapitalAmount = this.paymentCalculatorService.calculateCapital(invoiceTotalAmount);
          this.form.get('capitalAmount')?.setValue(defaultCapitalAmount);
          this.calculateTotalAmount();
          setTimeout(() => {
            this.capitalAmountInput.nativeElement.focus();
          });
        } else {
          this.form.get('capitalAmount')?.setValue(0);
          this.calculateTotalAmount();
        }
      })
    );
    this.subscription.add(
      this.form.get('invoiceTotalAmount')?.valueChanges.subscribe(result => {
        this.calculateTotalAmount();
        //this.onChange
      })
    );
    this.subscription.add(
      this.form.get('capitalAmount')?.valueChanges.subscribe(result => {
        this.calculateTotalAmount();
      })
    );
    this.subscription.add(
      this.form.get('enableTaxAmount')?.valueChanges.subscribe(result => {
        this.disableEnableControl('taxAmount', result);
        if(!result) {
          this.form.get('taxAmount')?.setValue(null);
        } else {
          setTimeout(() => {
              this.taxAmountInput.nativeElement.focus();
          });
        }
      })
    );
    this.subscription.add(
      this.form.get('enableShippingAmount')?.valueChanges.subscribe(result => {
        this.disableEnableControl('shippingAmount', result);
        if(!result) {
          this.form.get('shippingAmount')?.setValue(null);
        } else {
          setTimeout(() => {
              this.shippingAmountInput.nativeElement.focus();
          });
        }
      } )
    );
    // this.subscription.add(
    //   this.form.valueChanges.subscribe(result => {
    //     this.form.get('isFullyValidated')?.setValue(this.form.valid);
    //     console.log("valuechange", result, this.form.valid);
    // //    this.onChange(result ?? {});
    //    //// console.log(this.form.valid);
    //   }));
    
    

    // this.subscription.add(
    //   this.form.valueChanges.subscribe(result => {

    //     const totalAmount = parseFloat(result.invoiceTotalAmount ?? 0) + parseFloat(result.capitalAmount ?? 0);
    //     this.form.get('totalAmount')?.setValue(
    //       this.paymentCalculatorService.round(totalAmount),
    //       { emitEvent: true }
    //     );

    //     this.onChange(result ?? {});
    //   })
    // );
    // this.subscription.add(
    //   this.form.get('invoiceTotalAmount')?.valueChanges.subscribe(result => {




    //     const capitalAmountControl = this.form.get('capitalAmount');


    //     const capitalAutoAmountControl = this.form.get('capitalAutoAmount');

    //     const isCapitalValueChangedManually =
    //       (capitalAutoAmountControl?.getRawValue() != capitalAmountControl?.getRawValue());

    //     const invoiceTotalAmount = parseFloat(result);
    //     const capitalAmount = this.paymentCalculatorService.calculateCapital(invoiceTotalAmount);
    //     capitalAutoAmountControl?.setValue(capitalAmount, { emitEvent: false });
    //     if (!isCapitalValueChangedManually && !capitalAmountControl?.disabled) {
    //       capitalAmountControl?.setValue(capitalAmount, { emitEvent: false });
    //     }
    //   })
    // );
  }

  writeValue(data: InvoiceDetailsInterface): void {
    this.form.patchValue(data);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  disableEnableControl(name: string, enable: boolean): void {
    if (enable) {
      this.form.get(name)?.enable();
    } else {
      this.form.get(name)?.disable();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  

}
