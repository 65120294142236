import { IApiConfig } from './api.interface';
import { ApiMockService } from './api-mock.service';
import { ApiFactoryFacade } from './api-factory.facade';
import { Injectable } from '@angular/core';

@Injectable()
export class ApiMockFactoryService extends ApiFactoryFacade {
  create(config: IApiConfig) {
    return new ApiMockService(config);
  }
}
