<div class="page__header">
  <div class="page__header-flex">
    <div class="page__header-left">
      <div class="logo">
        <div class="logo__image"><img src="assets/logos/freely-logo-black.png" alt="History"/></div>
        <div class="logo__slogan">accept</div>
      </div>
    </div>
    <div class="page__header-right">
      <button mat-stroked-button routerLink="/history">exit</button>
    </div>
  </div>
</div>
<div class="success-hero">
  <div class="success-hero__media">
    <img src="assets/storm-small.svg" alt="Success"/>
  </div>
  <div class="success-hero__content">
    <h2 class="success-hero__title">Success! Confirmation ID {{ transaction?.transactionResult?.authorizationCode }}</h2>
    <div class="success-hero__subtitle">Your transaction number is {{ transaction?.transactionId }}</div>
  </div>
  <div class="success-hero__actions">
    <button mat-button color="primary" type="button" routerLink="/collect">download receipt</button>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-6 flex">
      <div class="panel flex-grow">
        <div class="panel__title">Customer Details</div>
        <div class="panel__content">
          <dl class="dl-table container">
            <div class="row">
              <dt class="col-4"><span>Company name:</span></dt>
              <dd class="col-8"><span>{{ transaction?.customerDetails?.displayName }}</span></dd>
            </div>
            <div class="row">
              <dt class="col-4"><span>Account number:</span></dt>
              <dd class="col-8"><span>{{ transaction?.customerDetails?.companyAccountNumber }}</span></dd>
            </div>
            <div class="row">
              <dt class="col-4"><span>Email address:</span></dt>
              <dd class="col-8"><span>{{ transaction?.customerDetails?.contactEmail }}</span></dd>
            </div>
            <div class="row">
              <dt class="col-4"><span>Last name:</span></dt>
              <dd class="col-8"><span>{{ transaction?.customerDetails?.contactLastName }}</span></dd>
            </div>
            <div class="row">
              <dt class="col-4"><span>First name:</span></dt>
              <dd class="col-8"><span>{{ transaction?.customerDetails?.contactFirstName }}</span></dd>
            </div>
            <div class="row">
              <dt class="col-4"><span>Business phone:</span></dt>
              <dd class="col-8"><span>{{ transaction?.customerDetails?.contactPhoneNumber }}</span></dd>
            </div>
            <div class="row">
              <dt class="col-4"><span>Mobile phone:</span></dt>
              <dd class="col-8"><span>{{ transaction?.customerDetails?.mobilePhoneNumber }}</span></dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="panel">
              <div class="panel__title">Payment Details</div>
              <div class="panel__content">
                <div class="container">
                  <div class="row">
                    <div class="col-6">
                      <dl class="dl-table container">
                        <div class="row">
                          <dt class="col-7"><span>Card Type:</span></dt>
                          <dd class="col-5"><span>{{ transaction?.cardDetails?.cardType }}</span></dd>
                        </div>
                        <div class="row">
                          <dt class="col-7"><span>Card number:</span></dt>
                          <dd class="col-5"><span>{{ transaction?.cardDetails?.cardNumberRedacted }}</span></dd>
                        </div>
                        <div class="row">
                          <dt class="col-7"><span>Card expiration:</span></dt>
                          <dd class="col-5">
                            <span>
                            {{ transaction?.cardDetails?.cardExpiration | cardExpiration : getCardExpirationOptions() }}</span></dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="panel">
              <div class="panel__title">Invoice Details</div>
              <div class="panel__content">
                <div class="container">
                  <div class="row">
                    <div class="col-6">
                      <dl class="dl-table container">
                        <div class="row">
                          <dt class="col-7"><span>Invoice Number:</span></dt>
                          <dd class="col-5"><span>{{ transaction?.paymentDetails?.reference}}</span></dd>
                        </div>
                        <div class="row">
                          <dt class="col-7"><span>Invoice total:</span></dt>
                          <dd class="col-5"><span>{{ transaction?.paymentDetails?.totalApplied  ?? 0.00 | currency }}</span>
                          </dd>
                        </div>
                        <div class="row">
                          <dt class="col-7"><span>Tax:</span></dt>
                          <dd class="col-5"><span>{{ transaction?.paymentDetails?.totalTax ?? 0.00 | currency }}</span></dd>
                        </div>
                      </dl>
                    </div>
                    <div class="col-6">
                      <dl class="dl-table container">
                        <div class="row">
                          <dt class="col-7"><span>Capital:</span></dt>
                          <dd class="col-5"><span>{{ transaction?.paymentDetails?.costOfCapital ?? 0.00 | currency }}</span></dd>
                        </div>
                        <div class="row">
                          <dt class="col-7"><span>Shipping:</span></dt>
                          <dd class="col-5"><span>{{ transaction?.paymentDetails?.totalShipping ?? 0.00 | currency }}</span></dd>
                        </div>
                        <div class="row">
                          <dt class="col-7"><strong>Payment total:</strong></dt>
                          <dd class="col-5"><strong>{{ transaction?.paymentDetails?.totalCharge ?? 0.00 | currency }}</strong>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="success-footer">
  <div class="success-footer__actions">
    <button mat-raised-button color="secondary-inversed">send a copy</button>
    <button mat-raised-button color="primary" routerLink="/payment/collect">collect another payment</button>
  </div>
</div>
