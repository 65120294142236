import { Component } from '@angular/core';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { NgClass } from '@angular/common';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-layout-empty',
  standalone: true,
  imports: [
    FooterComponent,
    HeaderComponent,
    NgClass,
    RouterOutlet
  ],
  templateUrl: './layout-empty.component.html',
})
export class LayoutEmptyComponent {

}
