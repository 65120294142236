import { Component, forwardRef, HostListener, inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { GridComponent } from '../../../../../shared/components/grid/grid.component';
import { CustomerModel } from '../../../../customer/customer.model';
import { customersConfig } from '../../../../customer/config/customers';
import { PaymentCollectStore } from '../../../store/payment-collect.store';

@Component({
  selector: 'app-payment-collect-customers',
  templateUrl: './customers.component.html',
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => PaymentCollectCustomersComponent)
  }],
  standalone: true,
  imports: [
    GridComponent
  ],
})
export class PaymentCollectCustomersComponent implements ControlValueAccessor {
  config = customersConfig;

  protected onChange: (value: CustomerModel) => void = () => {};
  protected onTouched: () => void = () => {};
  protected selected: CustomerModel = {};

  @HostListener('click', [ '$event' ])
  onClick() {
    this.onTouched();
  }
  readonly store = inject(PaymentCollectStore);
  $data = this.store.data.customers;

  writeValue(customer: CustomerModel): void {
    this.selected = customer;
  }

  registerOnChange(fn: (value: CustomerModel) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  selectCustomer(customer: CustomerModel) {
    this.selected = customer;
    this.onChange(this.selected);
  }

  isSelectedFunc(): (customer: CustomerModel) => boolean {
    return (customer: CustomerModel) => (this.selected?.id === customer.id);
  }
}
