<div class="header-left">
  <div class="header-left__logo">
    <img alt="Freely Logo" class="header-left__logo-img1" src="/assets/freely-logo-white.png">
    <img alt="Freely Accept Logo" class="header-left__logo-img2" src="/assets/accept-white.png">
  </div>
</div>
<div class="header-middle">
  <div class="header-nav">
    <a routerLink="/dashboard">Dashboard</a>
    <a routerLink="/history">History</a>
    <a routerLink="/customers">Customers</a>
    <a routerLink="/reports">Reports</a>
  </div>
</div>
<div class="header-right">
  <button mat-raised-button routerLink="/payment/collect">collect payment</button>
  <button
    class="admin-menu__button"
    (click)="isOpen = !isOpen" type="button" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
  ><app-avatar size="medium" src="/assets/avatar.svg" /></button>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayPositions]="[{
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top'
    }]"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
  >
    <div class="admin-menu">
      <button class="admin-menu__close" (click)="isOpen = false">
        <mat-icon fontSet="fa" fontIcon="fa-xmark" />
      </button>
      <div class="admin-menu__top">
        <img src="/assets/admin/avatar.png" height="100" width="100"/>
        <div class="admin-menu__info">
          <div class="admin-menu__info-name">
            <h4>Sysco Food</h4>
            business details
          </div>
          <button mat-raised-button color="primary"
                  routerLink="/administration"
                  (click)="isOpen = false"
          >Administration</button>
        </div>
      </div>
      <div class="admin-menu__middle">
        <ul class="admin-menu__items">
          <li class="admin-menu__item">
            <div class="admin-menu__item-wrapper">
              <div class="admin-menu__item-media"></div>
              <div class="admin-menu__item-name">
                Tom's Wholesale<br>
                business detaill
              </div>
            </div>
          </li>
          <li class="admin-menu__item">
            <div class="admin-menu__item-wrapper">
              <div class="admin-menu__item-media"></div>
              <div class="admin-menu__item-name">
                Alaskan Salmon Traders<br>
                business detail
              </div>
            </div>
          </li>
          <li class="admin-menu__item">
            <div class="admin-menu__item-wrapper">
              <div class="admin-menu__item-media"></div>
              <div class="admin-menu__item-name">
                Raw Produce of New York<br>
                business detail
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="admin-menu__bottom">
        <div class="admin-menu__actions">
          <button mat-raised-button mat-stroked-button>Add Account</button>
          <button mat-raised-button mat-stroked-button type="button" (click)="logout()">Sign Out</button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
