import { Injector, NgModule } from '@angular/core';
import { Config, provideConfig } from 'core/config';
import { RequestOptionsType } from 'core/api/services/api.interface';
import { AuthStore } from '../store/auth.store';
import { HttpHeaders } from '@angular/common/http';
import { UserAuthService } from '../services/user-auth.service';

@NgModule({
  providers: [

    provideConfig({
      api: {
        endpoints: {
          userDashboard: 'stats/transactions',
        },
        authFactory: (injector: Injector) => {
          return (options: RequestOptionsType) => {
            console.log("authFactory");
            const authStore = injector.get(AuthStore);
            options = options ?? {};
            let headers = options?.headers
              ? (options.headers instanceof HttpHeaders ? options.headers : new HttpHeaders(options.headers))
              : new HttpHeaders();

            headers = headers.set('Authorization', `Bearer ${ authStore.accessToken() }`);
            options['headers'] = headers;

            return options;
          }
        },

        authRefreshFactory: (injector: Injector) => {
          return () => {
            const userAuthService = injector.get(UserAuthService);
            return userAuthService.refreshToken();
          }
        }
      },
      apiAuth: {
        endpoints: {
          userLogin: { path: 'auth/login', auth: false },
          userLogout: { path: 'auth/logout' },
          userRefreshToken: { path: 'auth/refreshToken' },
        },

      }
    })
  ],
})
export class ConfigModule { }
