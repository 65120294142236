import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatButton } from '@angular/material/button';
import { CdkStep } from '@angular/cdk/stepper';
import { CustomerModel, PaymentMethodModel } from '../../../customer/customer.model';
import { PaymentCollectStepperComponent } from '../../components/payment-collect/stepper/stepper.component';
import { PaymentCollectCustomersComponent } from '../../components/payment-collect/customers/customers.component';
import {
  PaymentCollectPaymentMethodsComponent
} from '../../components/payment-collect/payment-methods/payment-methods.component';
import { InvoiceDetailsComponent, } from '../../components/payment-collect/invoice-details/invoice-details.component';
import { SummaryComponent } from '../../components/payment-collect/summary/summary.component';
import { OverlayService } from 'shared/services/overlay.service';
import { InvoiceDetailsInterface } from '../../payment.model';
import { TransactionsService } from '../../../transactions/services/transactions.service';
import {
  TransactionPurchaseFreelyPaymentPayload,
  TransactionPurchaseLineItemPayload,
  TransactionPurchasePayload,
  TransactionSalePayload
} from '../../../transactions/transaction.model';
import { PaymentCollectStore } from '../../store/payment-collect.store';
import { ModalComponentInterface, ModalService } from 'shared/components/modal/modal.service';
import {
  CustomerDetailsFormComponent
} from '../../../customer/components/customer-details-form/customer-details-form.component';
import { CustomersService } from '../../../customer/services/customers.service';
import { NotificationService } from 'shared/services/notification.service';
import { CardDetailsFormComponent } from '../../components/card-details-form/card-details-form.component';
import { ModalComponent } from 'shared/components/modal/modal/modal.component';


export type PaymentCollectFormType = FormGroup<{
  customer: FormControl<CustomerModel | null>,
  payment: FormControl<PaymentMethodModel | null>,
  invoice: FormControl<InvoiceDetailsInterface | null>,
}>;

@Component({
  selector: 'app-collect',
  standalone: true,
  imports: [
    PaymentCollectStepperComponent,
    PaymentCollectCustomersComponent,
    PaymentCollectPaymentMethodsComponent,
    InvoiceDetailsComponent,
    SummaryComponent,

    RouterLink,
    MatButton,
    CdkStep,
    ReactiveFormsModule
  ],
  templateUrl: './collect.component.html',
  host: {
    class: 'page page-payment-collect'
  }
})
export class CollectComponent implements OnInit, OnDestroy {
  @ViewChild('invoiceDetails') invoiceDetails!: InvoiceDetailsComponent;

  readonly store = inject(PaymentCollectStore);

  form: PaymentCollectFormType = new FormGroup({
    customer: new FormControl<CustomerModel | null>(null, [Validators.required]),
    payment: new FormControl<PaymentMethodModel | null>(null, [Validators.required]),
    invoice: new FormControl<InvoiceDetailsInterface | null>(null, [
      Validators.required,
      control => this.invoiceDetailsForm?.valid ? null : { invalidInvoiceDetails: true }
    ]),
  });

  invoiceDetailsForm: UntypedFormGroup = new UntypedFormGroup({});




  subscription = new Subscription();

  constructor(
    protected route: Router,
    protected overlayService: OverlayService,
    protected transactionsApiService: TransactionsService,
    protected modalService: ModalService,
    protected customerService: CustomersService,
    protected notificationService: NotificationService
  ) {
  }

  $allowNext: boolean = false;

  ngOnInit(): void {
    this.subscription.add(
      this.form.valueChanges.subscribe(result => {
        console.log(result);

        result.customer && this.store.addCustomer(result.customer);
        result.payment && this.store.addPayment(result.payment);
        result.invoice && this.store.addInvoice(result.invoice);
      })

    
    );

    this.subscription.add(
      this.invoiceDetailsForm.statusChanges.subscribe(status => {
        console.log("ypypy", status);
      }
    )
  );



    // this.subscription.add(
    //   this.invoiceDetails.form.get('invoiceNumber')?.valueChanges.subscribe(value => {  
    //     console.log(value);
    //   }));
    // this.subscription.add(
    //   this.invoiceDetails.form.statusChanges.subscribe(status => {
    //     console.log("ypypy", status);
    //   }));
  } 

  collectPaymentData(): TransactionSalePayload {
    const formData = this.form.getRawValue();
    return {
      customerId: String(formData!.customer!.id),
      tokenId: <string>formData!.payment!.id,
      reference: <string>formData!.invoice!.invoiceNumber,
      amount: Number(formData!.invoice!.totalAmount),
      cvv: <string>formData!.payment!.cvv,
      avsAddress: <string>formData!.payment!.streetAddress,
      avsZip: <string>formData!.payment!.zipCode,
      taxAmount: Number(formData!.invoice!.taxAmount ?? 0),
      shippingAmount: Number(formData!.invoice!.shippingAmount ?? 0),
      costOfCapitalAmount: Number(formData!.invoice!.capitalAmount ?? 0)
    };
  }

  collectFreelyPaymentData(): TransactionPurchaseFreelyPaymentPayload {
    const formData = this.form.getRawValue();
    const invoices = this.collectInvoicesData();
    const freelyPayment: TransactionPurchaseFreelyPaymentPayload = {
      LineItems: invoices,
      totalPaymentAmount: 0,
      totalAmountLineItemDetails: 0,
      totalShippingAmount: 0,
      shippingZip: '',
      totalTaxAmount: 0,
      costOfCapitalAmount: 0
    }
    invoices.forEach(invoice => {
      freelyPayment.totalPaymentAmount! += invoice.amount!;
      freelyPayment.totalAmountLineItemDetails! += invoice.amount!;
      freelyPayment.totalShippingAmount! += invoice.shippingAmount!;
      freelyPayment.shippingZip! = invoice.shippingZip!;
      freelyPayment.totalTaxAmount! += invoice.taxAmount!;
      freelyPayment.costOfCapitalAmount! += invoice.costOfCapitalAmount!;

    });
    return freelyPayment;
  }

  collectInvoicesData(): TransactionPurchaseLineItemPayload[] {
    const formData = this.form.getRawValue();
    return [{
      amount: Number(formData.invoice!.invoiceTotalAmount),
      accountingReference: <string>formData!.invoice!.invoiceNumber,
      shippingZip: <string>formData!.payment!.zipCode,
      shippingAmount: Number(formData!.invoice!.shippingAmount ?? 0),
      taxAmount: Number(formData!.invoice!.taxAmount ?? 0),
      costOfCapitalAmount: Number(formData!.invoice!.capitalAmount ?? 0),
      notes: ''
    }]
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    const formData = this.form.getRawValue();

    const freelyPayment = this.collectFreelyPaymentData();

    const salePayload = this.collectPaymentData();





    this.overlayService.showOverlay();
    this.transactionsApiService
      .sale(salePayload).pipe(
      tap(() => {
        this.overlayService.hideOverlay()
      })
    ).subscribe({
      next: (transaction) => {
        this.store.addTransaction(transaction);
        this.route.navigate(
          [ '/payment/collect-success' ],
          { queryParams: { transactionId: transaction.transactionId } }
        );
      },
      error: (error) => {
        this.overlayService.hideOverlay();
        this.modalService.openModal({
          body: 'Purchase failed is failed due to an error, please try again',
          dismiss: null,
          confirm: {
            label: 'Close',
            action: (modal: ModalComponentInterface) => {
              modal.close();
            }
          }
        });
      }
    });

  }

  openAddCustomerModal() {
    this.modalService.openModal({
      title: 'Customer Information',
      body: CustomerDetailsFormComponent,
      data: {},
      confirm: {
        label: 'Save',
        action: (modal, customerFormComponent: CustomerDetailsFormComponent) => {
          const form = customerFormComponent.form;
          if (!form.valid) {
            form.markAllAsTouched();
            return;
          }
          const formData = form.getRawValue();
          const customerModel: CustomerModel = {
            contactFirstName: <string>formData!.contactFirstName,
            contactLastName: <string>formData!.contactLastName,
            displayName: <string>formData!.displayName,
            companyName: <string>formData!.companyName,
            companyAccountNumber: <string>formData!.companyAccountNumber,
            contactEmail: <string>formData!.contactEmail,
            contactPhoneNumber: <string>formData!.businessPhone + ' ' + <string>formData!.businessPhoneExt,
            mobilePhoneNumber: <string>formData!.mobilePhoneNumber,
            notes: <string>formData!.notes,
          };

          this.customerService.createCustomer(customerModel)
            .pipe(
              tap(() => this.overlayService.showOverlay())
            )
            .subscribe({
              next: (customer) => {
                modal.close();
                this.overlayService.hideOverlay();
                this.notificationService.success('Customer created successfully');
              },
              error: (error) => {
                this.overlayService.hideOverlay()
                this.notificationService.error(error.message);
              }
            });
        },
      },
      dismiss: (component: any) => {
        component.dialogRef.close();
      }
    });
  }

  openAddPaymentMethodModal() {
    this.modalService.openModal({
      title: 'Add Payment Card',
      body: CardDetailsFormComponent,
      data: {},
      confirm: {
        label: 'Save',
        action: (modal: ModalComponentInterface, cardDetailsFormComponent: CardDetailsFormComponent) => {
          const form = cardDetailsFormComponent.form;
          if (!form.valid) {
            form.markAllAsTouched();
            return;
          }
          const formData = form.getRawValue();
          const paymentMethodModel: PaymentMethodModel = {
            account: <string>formData!.account,
            cardHolderName: <string>formData!.cardHolderName,
            expirationDate: <string>formData!.expirationDate?.replace('/', ''),
            cvv: <string>formData!.cvv,
            streetAddress: <string>formData!.streetAddress,
            isDefault: <boolean>formData!.isDefault,
            zipCode: <string>formData!.zipCode,
            bypassCVV: <boolean>formData!.bypassCVV,
            bypassAVS: <boolean>formData!.bypassAVS,
            //notes: <string>formData!.notes,
          };

          const customer = this.store.customer();
          if (!customer) {
            this.notificationService.error('Please select a customer');
            return;
          }

          this.customerService.createPaymentMethod(<string>customer!.id, paymentMethodModel)
            .pipe(
              tap(() => this.overlayService.showOverlay())
            )
            .subscribe({
              next: (customer) => {
                this.store.reloadPaymentMethods();
                modal.close();
                this.overlayService.hideOverlay();
                this.notificationService.success('Payment card created successfully');
              },
              error: (error) => {
                console.log(error);
                this.overlayService.hideOverlay()
                if(error.data?.details?.code === 'FAILED CARD VERIFY') {
                  cardDetailsFormComponent.invalidMessage = "Invalid Card Details";
                  cardDetailsFormComponent.isCardInvalid = true;
                  cardDetailsFormComponent.form.updateValueAndValidity();
                  cardDetailsFormComponent.accountInput.nativeElement.focus();
                } else if(error.data?.details?.code === 'BAD CID') {
                  cardDetailsFormComponent.invalidMessage = "Invalid CVV";
                  cardDetailsFormComponent.isCvvInvalid = true;
                  cardDetailsFormComponent.isCardInvalid = true;
                  cardDetailsFormComponent.form.updateValueAndValidity();
                  cardDetailsFormComponent.cvvInput.nativeElement.focus();
                }
                else if(error.data?.details?.code === 'BAD AVS') { 
                  cardDetailsFormComponent.invalidMessage = "Invalid Address/ZIP";
                  cardDetailsFormComponent.isAvsInvalid = true;
                  cardDetailsFormComponent.isCardInvalid = true;
                  cardDetailsFormComponent.form.updateValueAndValidity();
                  cardDetailsFormComponent.addressInput.nativeElement.focus();
                }
                else if (error.data?.details?.code) {
                  cardDetailsFormComponent.invalidMessage = error.data?.details?.code;
                  cardDetailsFormComponent.form.updateValueAndValidity();
                  cardDetailsFormComponent.cvvInput.nativeElement.focus();

                } else {
                  let message = `An error occurred while creating payment card. Please try again later.  Error code:${error.code }`;
                  this.notificationService.error(message);
                }
              }
            });
        },
      },
      dismiss: {
        label: 'Abort',
        action: (modal: ModalComponentInterface) => {
          modal.close();
        }
      }
      
    
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  asfasd() {
    console.log('asfasd');
  }
}
