import { NgModule } from '@angular/core';
import { AdminRoutingModule } from './admin/admin-routing.module';
import { UserModule } from './user/user.module';
import { TransactionsModule } from './transactions/transactions.module';
import { PaymentModule } from './payment/payment.module';
import { CustomerModule } from './customer/customer.module';
import { ReportsModule } from './reports/reports.module';
import { AdminModule } from './admin/admin.module';

@NgModule({
  imports: [
    UserModule,
    TransactionsModule,
    PaymentModule,
    CustomerModule,
    AdminModule,
    ReportsModule
  ]
})
export class FeaturesModule { }
