<div class="container" [formGroup]="form">
  <div class="row">
    <div class="col-12">
      <fieldset>
        <legend>Summary</legend>
        <div class="form-control-group">
          <div class="form-control">
            <mat-form-field appearance="outline">
              <mat-label>Reference</mat-label>
              <input formControlName="invoiceNumber" matInput prefix="ref:" mask="A*">
              <mat-error>
                @if (form.controls['invoiceNumber'].hasError('required')) {
                  Reference is required
                }
                @else if (form.controls['invoiceNumber'].hasError('minlength')) {
                  Minimum 3 characters
                }
                @else if (form.controls['invoiceNumber'].hasError('maxlength')) {
                 Maximum 25 characters
                }
              </mat-error>
            </mat-form-field>

          </div>
          <div class="form-control">
            <mat-form-field appearance="outline" ngClass="currency">
              <mat-label>Invoice Total</mat-label>
                <input formControlName="invoiceTotalAmount" matInput currencyMask  >
                <mat-error>
                  @if (form.controls['invoiceTotalAmount'].hasError('required')) {
                    Total Amount is required
                  }
                  @else if (form.controls['invoiceTotalAmount'].hasError('min')) {
                    Minimum 0.01
                  }
                  @else if (form.controls['invoiceTotalAmount'].hasError('max')) {
                    Maximum 99999.99
                  }
                </mat-error>
            </mat-form-field>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
  <div class="row">
    <div class="col-9">
      <fieldset class="">
        <legend>Details</legend>
        <div class="form-control-group">
          <div>
            <div class="form-control">
              <mat-slide-toggle formControlName="enableTaxAmount">tax</mat-slide-toggle>
            </div>
            <div class="form-control">
              <mat-form-field appearance="outline" ngClass="currency">
                <mat-label>Tax</mat-label>
                <input #taxAmountInput formControlName="taxAmount" matInput currencyMask >
                <mat-error>
                  @if (form.controls['taxAmount'].hasError('required')) {
                    Tax is required
                  }
                  @else if (form.controls['taxAmount'].hasError('min')) {
                    Minimum 0.01
                  }
                  @else if (form.controls['taxAmount'].hasError('maxTaxAmount')) {
                    Max 15%
                  }
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div>
            <div class="form-control">
              <mat-slide-toggle formControlName="enableShippingAmount">shipping</mat-slide-toggle>
            </div>
            <div class="form-control">
              <mat-form-field appearance="outline" ngClass="currency">
                <mat-label>Shipping</mat-label>
                <input #shippingAmountInput formControlName="shippingAmount" matInput currencyMask >
                <mat-error>
                  @if (form.controls['shippingAmount'].hasError('required')) {
                    Amount is required
                  }
                  @else if (form.controls['shippingAmount'].hasError('min')) {
                    Minimum 0.01
                  }
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div>
            <div class="form-control">
              <mat-slide-toggle  formControlName="enableCapitalAmount">add capital costs</mat-slide-toggle>
            </div>
            <div class="form-control">
              <mat-form-field appearance="outline" ngClass="currency">
                <mat-label>Charge</mat-label>
                <input #capitalAmountInput formControlName="capitalAmount" matInput currencyMask >
                <mat-error>
                  @if (form.controls['capitalAmount'].hasError('required')) {
                    Amount is required
                  }
                  @else if (form.controls['capitalAmount'].hasError('min')) {
                    Minimum 0.01
                  }
                  @else if (form.controls['capitalAmount'].hasError('maxCapitalAmount')) {
                    Max 15%
                  }
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="col-3 app-invoice-details__form-totals">
      <fieldset>
        <legend>Total</legend>
        <div class="form-control-group align-bottom">
          <div class="">
            <div class="form-control">
              <mat-form-field appearance="outline" ngClass="currency">
                <mat-label>Payment total</mat-label>
                <input readonly formControlName="totalAmount" matInput currencyMask >
              </mat-form-field>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</div>
