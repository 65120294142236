<div [formGroup]="form" class="container">
  <div class="row">
    <div class="col-6">
      <div class="form-control">
        <mat-form-field appearance="outline">
          <mat-label>Card Number</mat-label>
          <input matInput #accountInput formControlName="account" [readonly]="isViewMode" [mask]="cardMask"    >
          <mat-error>
            @if (form.controls.account.hasError('required')) {
             Valid Card is required
            }
            @else if (form.controls.account.hasError('pattern')) {
              Card number is invalid
            }
          </mat-error>
        </mat-form-field>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-6">
            <div class="form-control">
              <mat-form-field appearance="outline">
                <mat-label>Exp. Date</mat-label>
                <input matInput #expirationDateInput placeholder="MM YY" formControlName="expirationDate" [readonly]="isViewMode" mask="00 00">
                <mat-error>
                  @if (form.controls.expirationDate.hasError('required')) {
                    required
                  }
                  @else if(form.controls.expirationDate.hasError('pattern')) {
                    Invalid Expiration 
                  }
                  @else if(form.controls.expirationDate.hasError('expirationDateInvalid')) {
                    Card Expired
                  }
                  @else if(form.controls.expirationDate.hasError('expirationDateTooFar')) {
                    Invalid Expiration
                  }
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-6">
            <div class="form-control">
              <mat-form-field appearance="outline">
                <mat-label>CVV Code</mat-label>
                <input matInput #cvvInput formControlName="cvv" [readonly]="isViewMode" [mask]="cvvMask">
                @if(isCvvInvalid) {
                  <input matCheckbox type="checkbox" formControlName="bypassCVV" [readonly]="isViewMode"> Override 
                }
                <mat-error>
                  @if (form.controls.cvv.hasError('required')) {
                    CVV is required
                  }
                  @else if(isCvvInvalid) {
                    Verify Failed
                  }
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="form-control">
        <mat-form-field appearance="outline">
          <mat-label>Notes</mat-label>
          <textarea matInput #notesInput formControlName="notes" [readonly]="isViewMode"></textarea>
          <mat-error></mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-6">
      <div class="form-control">
        <mat-form-field appearance="outline">
          <mat-label>Card Holder Name</mat-label>
          <input matInput #cardHolderNameInput formControlName="cardHolderName" [readonly]="isViewMode">
          <mat-error>
            @if (form.controls.cardHolderName.hasError('required')) {
              Card holder name is required
            }
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-control">
        <mat-form-field appearance="outline">
          <mat-label>Street Address</mat-label>
          <input matInput #addressInput formControlName="streetAddress" [readonly]="isViewMode">
          @if(isAvsInvalid) {
            <input matCheckbox type="checkbox" formControlName="bypassAVS" [readonly]="isViewMode"> Override 
          }
          <mat-error>
            @if (form.controls.streetAddress.hasError('required')) {
              Street address is required
            }
            @else if(form.controls.streetAddress.hasError('maxlength')) {
              Street address too long
            }
            @else if (isAvsInvalid) {
              AVS Failed
            }
          </mat-error>
        </mat-form-field>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-6">
            <div class="form-control">
              <mat-form-field appearance="outline">
                <mat-label>Zip Code</mat-label>
                <input matInput #zipInput formControlName="zipCode" [readonly]="isViewMode">
                <mat-error>
                  @if (form.controls.zipCode.hasError('required')) {
                    Zip required
                  }
                  @else if (form.controls.zipCode.hasError('pattern')) {
                    Invalid Zip
                  }
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-6">
            <div class="form-control">
              <mat-slide-toggle #defaultInput [disabled]="isViewMode">set as default</mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        @if(isCardInvalid==false) {
           *Required Fields
        }
        @else {
          <mat-error>
            {{invalidMessage}}
          </mat-error>
        }
      </div>
      <!-- <div class="form-control">
        <mat-slide-toggle #defaultInput [disabled]="isViewMode">set as default</mat-slide-toggle>
      </div> -->

    </div>

  </div>
</div>
