<div class="container">
  <div class="row">
    <div class="col-6 flex">
      <div class="panel flex-grow">
        <button mat-mini-fab ngClass="button-edit" (click)="onEditClick('customer')">
          <mat-icon fontSet="fa" fontIcon="fa-pen"/>
        </button>
        <div class="panel__title">Customer Details</div>
        <div class="panel__content">
          <dl class="dl-table container">
            <div class="row">
              <dt class="col-4"><span>Company name:</span></dt>
              <dd class="col-8"><span>{{ form?.getRawValue()?.customer?.companyName ?? '-' }}</span></dd>
            </div>
            <div class="row">
              <dt class="col-4"><span>Account number:</span></dt>
              <dd class="col-8"><span>{{ form?.getRawValue()?.customer?.companyAccountNumber ?? '-' }}</span></dd>
            </div>
            <div class="row">
              <dt class="col-4"><span>Email address:</span></dt>
              <dd class="col-8"><span>{{ form?.getRawValue()?.customer?.contactEmail ?? '-' }}</span></dd>
            </div>
            <div class="row">
              <dt class="col-4"><span>Last name:</span></dt>
              <dd class="col-8"><span>{{ form?.getRawValue()?.customer?.contactLastName ?? '-' }}</span></dd>
            </div>
            <div class="row">
              <dt class="col-4"><span>First name:</span></dt>
              <dd class="col-8"><span>{{ form?.getRawValue()?.customer?.contactFirstName ?? '-' }}</span></dd>
            </div>
            <div class="row">
              <dt class="col-4"><span>Business phone:</span></dt>
              <dd class="col-8"><span>{{ form?.getRawValue()?.customer?.contactPhoneNumber ?? '-' }}</span></dd>
            </div>
            <div class="row">
              <dt class="col-4"><span>Mobile phone:</span></dt>
              <dd class="col-8"><span>{{ form?.getRawValue()?.customer?.mobilePhoneNumber ?? '-' }}</span></dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="panel">
              <button mat-mini-fab ngClass="button-edit" (click)="onEditClick('payment')">
                <mat-icon fontSet="fa" fontIcon="fa-pen"/>
              </button>
              <div class="panel__title">Payment Details</div>
              <div class="panel__content">
                <div class="container">
                  <div class="row">
                    <div class="col-6">
                      <dl class="dl-table container">
                        <div class="row">
                          <dt class="col-7"><span>Card Type:</span></dt>
                          <dd class="col-5"><span>{{ form?.getRawValue()?.payment?.accountType }}</span></dd>
                        </div>
                        <div class="row">
                          <dt class="col-7"><span>Card number:</span></dt>
                          <dd class="col-5"><span>{{ form?.getRawValue()?.payment?.account | ccMask }}</span></dd>
                        </div>
                        <div class="row">
                          <dt class="col-7"><span>Card expiration:</span></dt>
                          <dd class="col-5">
                            <span>
                            {{ form?.getRawValue()?.payment?.expirationDate | cardExpiration : getCardExpirationOptions() }}</span></dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="panel">
              <button mat-mini-fab ngClass="button-edit" (click)="onEditClick('invoice')">
                <mat-icon fontSet="fa" fontIcon="fa-pen"/>
              </button>
              <div class="panel__title">Invoice Details</div>
              <div class="panel__content">
                <div class="container">
                  <div class="row">
                    <div class="col-6">
                      <dl class="dl-table container">
                        <div class="row">
                          <dt class="col-7"><span>Invoice Number:</span></dt>
                          <dd class="col-5"><span>{{ form?.getRawValue()?.invoice?.invoiceNumber }}</span></dd>
                        </div>
                        <div class="row">
                          <dt class="col-7"><span>Invoice total:</span></dt>
                          <dd class="col-5"><span>{{ sanitizeCurrency(form?.getRawValue()?.invoice?.invoiceTotalAmount ?? 0) | currency }}</span>
                          </dd>
                        </div>
                        <div class="row">
                          <dt class="col-7"><span>Tax:</span></dt>
                          <dd class="col-5"><span>{{ sanitizeCurrency(form?.getRawValue()?.invoice?.taxAmount ?? 0) | currency }}</span></dd>
                        </div>
                      </dl>
                    </div>
                    <div class="col-6">
                      <dl class="dl-table container">
                        <div class="row">
                          <dt class="col-7"><span>Capital:</span></dt>
                          <dd class="col-5"><span>{{ sanitizeCurrency(form?.getRawValue()?.invoice?.capitalAmount ?? 0) | currency }}</span></dd>
                        </div>
                        <div class="row">
                          <dt class="col-7"><span>Shipping:</span></dt>
                          <dd class="col-5"><span>{{ sanitizeCurrency(form?.getRawValue()?.invoice?.shippingAmount ?? 0) | currency }}</span></dd>
                        </div>
                        <div class="row">
                          <dt class="col-7"><strong>Payment total:</strong></dt>
                          <dd class="col-5"><strong>{{ sanitizeCurrency(form?.getRawValue()?.invoice?.totalAmount ?? 0) | currency }}</strong>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
