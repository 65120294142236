import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ConfigService } from './config.service';
import { provideConfig, provideDefaultConfig } from './config-providers';

export function configInitializerFactory(
  configService: ConfigService,
) {
  return () => {
    configService.initialize();
  }
}

@NgModule({

  providers: [
    provideDefaultConfig({}),
    provideConfig({}),
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configInitializerFactory,
      deps: [ConfigService],
      multi: true,
    },
  ]
})
export class ConfigModule { }
