import { NgModule } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { LayoutNoHeaderComponent } from '../common/components/layout-no-header/layout-no-header.component';
import { UserAuthGuard } from '../user/guards/user-auth-guard';
import { CollectSuccessComponent } from './pages/collect-success/collect-success.component';
import { CollectComponent } from './pages/collect/collect.component';


@NgModule({
  providers: [
    provideRouter([
      {
        path: 'payment',
        component: LayoutNoHeaderComponent,
        canActivate: [UserAuthGuard],
        children: [
          {
            path: 'collect',
            component: CollectComponent,
          },
          {
            path: 'collect-success',
            component: CollectSuccessComponent,
          }
        ]
      },
    ], withComponentInputBinding()),
  ]
})
export class PaymentRoutingModule { }
