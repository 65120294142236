import { UpperCasePipe } from '@angular/common';
import { paymentMethodActions } from './actions';
import { GridViewType } from '../../../shared/components/grid/grid-config';
import { PaymentMethodModel } from '../../customer/customer.model';
import { CardExpirationPipe } from '../../../shared/pipes/card-expiration.pipe';
import { LastModifiedPipe } from '../../../shared/pipes/last-modified.pipe';

export const paymentMethodsConfig: GridViewType<PaymentMethodModel> = {
  columns: [
    {
      code: 'actions',
      isActionColumn: true,
      actions: paymentMethodActions
    },
    {
      label: 'Card Type',
      code: 'accountType',
      pipes: [
        { pipe: UpperCasePipe },
      ],
      template: 'cardType',
    },
    {
      label: 'Card Number',
      code: 'account',
    },
    {
      label: 'Card Expiration',
      code: 'expirationDate',
      pipes: [
        {
          pipe: CardExpirationPipe,
          args: {
            fromFormat: /^(?<month>.{2})(?<year>.{2})$/,
            toFormat: 'MM/yy'
          }
        },
      ]
    },
    {
      label: 'Last Modified',
      code: 'updatedDateTime',
      pipes: [
        { pipe: LastModifiedPipe, args: 'short' },
      ]
    },
    {
      label: 'Default Payment',
      code: 'isDefault',
      class: 'column-default-payment',
      template: 'setDefault',
    }
  ]
};
