import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

export type MessageType = 'ERROR' | 'SUCCESS' | 'WARN';

export interface MessageConfig {
  heading?: string;
  message: string | Array<any>;
  type?: MessageType;
  duration?: number;
}

@Injectable({ providedIn: 'root' })
export class AlertMessageService {
  public alertEvent: Subject<MessageConfig> = new Subject<MessageConfig>();

  showErrorMessage(config: MessageConfig) {
    const message = this.getMessage(config.message);
    this.alertEvent.next({ ...config, message, type: 'ERROR' });
  }

  showSuccessMessage(config: MessageConfig) {
    const message = this.getMessage(config.message);

    this.alertEvent.next({ ...config, message, type: 'SUCCESS' });
  }
  showWarningMessage(config: MessageConfig) {
    const message = this.getMessage(config.message);

    this.alertEvent.next({ ...config, message, type: 'WARN' });
  }

  private getMessage(message: string | Array<any>) {
    return Array.isArray(message)
      ? message
          .flatMap((elem) => (typeof elem !== 'string' ? Object.keys(elem).map((key) => elem[key]) : elem))
          .join('<br/>')
      : message;
  }
}
