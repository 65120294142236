import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { RequestOptionsType } from './api.interface';

export class ApiMockService extends ApiService {
  protected override path2Url(path: string, options: RequestOptionsType = {}): string {
    path = this.interpolatePath(path, options?.urlParams ?? {});

    let paramsList = [];
    const paramsStr = options?.params instanceof HttpParams
      ? options?.params.toString()
      : (new HttpParams({ fromObject: options?.params })).toString();

    if (paramsStr) {
      path += '/' + paramsStr;
    }

    return `${ this.apiUrl }/${ path }.json`;
  }
}
