import { inject, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, mergeMap, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { template } from 'lodash-es';
import { environment } from 'environments/environment';
import { ApiResponseError } from '../api-model';
import { IApiConfig, IApiRequestEndpoint, IApiService, RequestOptionsType } from './api.interface';
import { UserAuthService } from 'app/features/user/services/user-auth.service';
import { Router } from '@angular/router';

const defaultEndpointConfig = {
  path: '',
  auth: true,
  headers: { 'Content-Type': 'application/json' }
}

export class ApiService implements IApiService {
  apiUrl: string;
  apiMode = environment?.apiMode ?? 'live';
  authFunction?: (options: RequestOptionsType) => RequestOptionsType;
  authRefreshFunction?: () => Observable<boolean>;

  

  protected injector = inject(Injector);
  protected http = inject(HttpClient);
  constructor(
    protected apiConfig?: IApiConfig
  ) {
    if (this.apiConfig) {
      this.apiUrl = this.apiConfig.apiUrl!;
      
      this.authFunction = this.apiConfig?.authFactory ? this.apiConfig?.authFactory(this.injector) : undefined;
      this.authRefreshFunction = this.apiConfig?.authRefreshFactory ? this.apiConfig?.authRefreshFactory(this.injector) : undefined;
    }
  }

  public endpointConfig(endpointName: string): IApiRequestEndpoint | null {
    const endpoint = this.apiConfig?.endpoints?.[endpointName] ?? null;
    if (typeof endpoint === 'string') {
      return {
        ...defaultEndpointConfig,
        path: endpoint,
      }
    } else if (typeof endpoint === 'object') {
      return {
        ...defaultEndpointConfig,
        ...endpoint
      }
    } else {
      return null;
    }
  }

  public getUrl(endpoint: string, data: { [key: string]: string } = {}): string {
    const endpointConfig = this.endpointConfig(endpoint);

    if (!endpointConfig) {
      throw new Error(`Endpoint ${ endpoint } not found`);
    }
    return this.path2Url(endpointConfig.path, data);
  }

  protected interpolatePath(path: string, data: { [key: string]: string } = {}): string {
    const compiled = template(path);
    return compiled(data);
  }

  protected path2Url(path: string,  options: RequestOptionsType = {}): string {
    path = this.interpolatePath(path, options?.urlParams ?? {});
    return `${ this.apiUrl }/${ path }`;
  }

  public request<T>(endpointName: string, method: string = 'get', options?: RequestOptionsType): Observable<T> {
    const endpointConfig = this.endpointConfig(endpointName);
    if (!endpointConfig) {
      throw new Error(`Endpoint ${ endpointName } not found`);
    }
    const url = this.path2Url(endpointConfig.path, options);

    options = options ?? {};
    let headers = options?.headers
      ? (options.headers instanceof HttpHeaders ? options.headers : new HttpHeaders(options.headers))
      : new HttpHeaders();


    for (const key in endpointConfig.headers) {
      headers = headers.set(key, endpointConfig.headers[key]);
    }
    options['headers'] = headers;

    if (endpointConfig.auth && this.authFunction instanceof Function) {
      options = this.authFunction(options);
      console.log('authFunction');
    }

    return this.http.request<T>(method, url, options as any).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (this.authRefreshFunction instanceof Function) {
          console.log('authRefreshFunction is true', error);
          switch (error.status) {
            case 401:

              const userAuthService = this.injector.get(UserAuthService);
              userAuthService.logout();

              const router = this.injector.get(Router);
              router.navigate(['/login']);
            

              throw new ApiResponseError("You are No Longer Logged In", 401);

              // console.log(userAuthService);
              //this.authRefreshFunction
              // this.router.navigate(['/login']);
              // return this.authRefreshFunction().pipe(
              //   mergeMap((result) => {
              //     if (result == true) {
              //       return this.request<T>(endpointName, method, options);
              //     } else {
              //       throw new ApiResponseError(error.message, error.code);
              //     }
              //   })
              // );
            default:
              throw new ApiResponseError(error.message, error.error.code || error.status, error.status, error.error);
          }
        } else {
          throw new ApiResponseError(error.message, error.code);
        }
      })
    ) as Observable<T>;
  }

  public get<T>(endpointName: string, options?: RequestOptionsType): Observable<T> {
    return this.request(endpointName, 'get', options);
  }

  public post<T>(endpointName: string, options?: RequestOptionsType): Observable<T> {
    return this.request(endpointName, 'post', options);
  }
  public put<T>(endpointName: string, options?: RequestOptionsType): Observable<T> {
    return this.request(endpointName, 'put', options);
  }
  public patch<T>(endpointName: string, options?: RequestOptionsType): Observable<T> {
    return this.request(endpointName, 'patch', options);
  }
  public delete<T>(endpointName: string, options?: RequestOptionsType): Observable<T> {
    return this.request(endpointName, 'delete', options);
  }
}
