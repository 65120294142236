import { NgModule } from '@angular/core';
import { ReportsRoutingModule } from './reports-routing.module';
import { ConfigModule } from './config/config.module';

@NgModule({
  imports: [
    ConfigModule,
    ReportsRoutingModule
  ]
})
export class ReportsModule { }
