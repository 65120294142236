import { NgModule } from '@angular/core';
import { provideConfig } from '../../../core/config/config-providers';
import { apiRoutes } from './api-routes';

@NgModule({
  providers: [
    provideConfig({
      api: apiRoutes
    })
  ],
})
export class ConfigModule { }
