import { NgModule } from '@angular/core';
import { PaymentRoutingModule } from './payment-routing.module';


@NgModule({
  imports: [
    PaymentRoutingModule,
  ]

})
export class PaymentModule { }
